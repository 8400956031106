import $ from 'jquery'
import { ready } from 'shared/ready'
import VisibilityObserver from '../support/VisibilityObserver'
import { Swiper, Lazy, Navigation, Scrollbar } from 'swiper'

Swiper.use([Lazy, Navigation, Scrollbar])

class AboutUsFeatureGalleryComponent {
  constructor(node) {
    this.$el = $(node)

    new VisibilityObserver(this.$el, {
      rootMargin: '50% 0% 50% 0%',
      callback: this.initializeSlider,
    })
  }

  initializeSlider = () => {
    new Swiper(this.$el.get(0), {
      effect: 'slider',
      centeredSlides: true,
      slidesPerView: 'auto',
      spaceBetween: 30,
      loop: true,
      navigation: {
        prevEl: '.feature-gallery-nav--prev',
        nextEl: '.feature-gallery-nav--next',
      },
      pagination: {
        el: '.gallery__pagination',
        clickable: true,
      },
      breakpoints: {
        375: {
          spaceBetween: 8,
        },
        768: {
          spaceBetween: 30,
        },
      },
      slideClass: 'feature-gallery__slide',
      wrapperClass: 'feature-gallery__wrapper',
    })
  }
}

ready(function () {
  $('.feature-gallery').each((_, node) => {
    new AboutUsFeatureGalleryComponent(node)
  })
})
