import $ from 'jquery'
import { ready } from 'shared/ready'
import matchMediaQueries from '../support/mediaQueries'
import { Collapse } from './collapse'

class BlogAsideToc {
  constructor($root) {
    this.$root = $root
    if (!this.$root.length) return

    this.initialized = false
    this.isModeMobile = false
    this.$title = $root.find('.blog-aside-toc__title')
    this.$body = $root.find('.blog-aside-toc__body')

    this.$title.on('click', this.handleToggleBody)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    new Collapse(this.$body, {
      itemClass: 'blog-aside-toc__section',
      toggleClass: 'blog-aside-toc__section-title',
      bodyClass: 'blog-aside-toc__list',
    })
  }

  handleToggleBody = () => {
    const isOpen = this.$root.hasClass('blog-aside-toc--open')
    this.$body[isOpen ? 'slideUp' : 'slideDown']({ duration: 200 })
    this.$root.toggleClass('blog-aside-toc--open')
  }

  handleResize = () => {
    const { isTablet } = matchMediaQueries()

    if (isTablet && (!this.isModeMobile || !this.initialized)) {
      this.isModeMobile = true
      this.initialized = true
    } else if (!isTablet && (this.isModeMobile || !this.initialized)) {
      // stop `slideDown/slideUp` animation and remove `style="display: none"`
      this.resetBody()

      this.isModeMobile = false
      this.initialized = true
    }
  }

  resetBody = () => {
    this.$body.stop().removeAttr('style')
    this.$root.removeClass('blog-aside-toc--open')
  }
}

// ---
// Init
// ---
ready(function () {
  return new BlogAsideToc($('.blog-aside-toc'))
})
