import $ from 'jquery'
import { ready } from 'shared/ready'
import VisibilityObserver from '../support/VisibilityObserver'
import modals from '../support/modals'

class FooterComponent {
  constructor(node) {
    this.$el = $(node)

    new VisibilityObserver(this.$el, {
      callback: this.initialize,
    })
  }

  initialize = () => {
    this.$footerSharingLinks = this.$el.find('.footer__social-link')
    this.$footerCountryButton = this.$el.find('.footer__country-switch')

    this.$footerSharingLinks.each((i, item) => {
      const $item = $(item)
      if (!$item.attr('href')) $item.on('click', e => this.handleShareClick(e, $item.data('type')))
    })

    this.$el.find('.footer__links-title').on('click', this.handleClick)
    this.$footerCountryButton.on('click', () => modals.changeCountryModal.open())
  }

  handleClick = e => {
    const $block = $(e.target).parent('.footer__links-block')
    const isBlockOpen = $block.hasClass('is-open')

    const $openBlock = this.$el.find('.footer__links-block.is-open')

    $openBlock.removeClass('is-open')
    $openBlock.find('.footer__links-list').animate({ height: 0 }, 300)

    if (!isBlockOpen) {
      $block.addClass('is-open')
      $block
        .find('.footer__links-list')
        .animate({ height: $block.find('.footer__links-list').get(0).scrollHeight }, 300)
    }
  }

  handleShareClick = (event, name) => {
    event.preventDefault()

    const url = this.$el.data('url')
    const title = this.$el.data('title')
    const text = this.$el.data('text')
    const textTwitter = this.$el.data('twitter')

    switch (name) {
      case 'facebook':
        this.openSharingPopup('https://www.facebook.com/sharer.php', {
          s: 100,
          'p[url]': url,
        })
        break
      case 'twitter':
        this.openSharingPopup('https://twitter.com/share', {
          text: textTwitter,
          url: url,
        })
        break
      case 'linkedin':
        this.openSharingPopup('https://www.linkedin.com/shareArticle', {
          mini: 'true',
          url: url,
          title: title,
          summary: text,
        })
        break
      case 'google':
        this.openSharingPopup(' https://plus.google.com/share', {
          text: textTwitter,
          url: url,
        })
        break
    }
  }

  openSharingPopup = (url, params = {}) => {
    const windowFeatures =
      'height=400, width=640, toolbar=no, menubar=no, ' +
      'scrollbars=no, resizable=no, location=no,' +
      'directories=no, status=no'

    const windowName = 'sharing_popup'

    const query = new URLSearchParams()
    for (const name in params) query.append(name, params[name])

    window.open(`${url}?${query.toString()}`, windowName, windowFeatures)
  }
}

// Init
ready(function () {
  $('.footer').each((i, node) => {
    new FooterComponent(node)
  })
})
