import $ from 'jquery'
import { ready } from 'shared/ready'

class BuilderBenefit {
  constructor(node) {
    this.$el = $(node)
    this.$visual = this.$el.find('.builder-benefits__item-visual')
    this.videoSrc = this.$visual.data('video-src')
    this.video = this.createVideoNode()
    this.wasPlayed = false

    $(this.video).one('canplay', () => {
      $(this.video).addClass('builder-benefits__item-video--ready')
    })

    if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window) {
      this.watchScrolling()
    } else {
      this.video.loop = true
      this.video.play()
    }
  }

  watchScrolling() {
    // Observer to load video before it comes into viewport
    this.loadObserver = new IntersectionObserver(this.handleLoadIntersection, {
      rootMargin: '100% 0% 100% 0%',
    })
    this.loadObserver.observe(this.video)

    // Observer to play/replay video as it comes into viewport
    this.playObserver = new IntersectionObserver(this.handlePlayIntersection)
    this.playObserver.observe(this.video)
  }

  createVideoNode() {
    return $(`<video muted="muted" preload="none" playsinline />`)
      .attr({
        src: this.videoSrc,
        type: 'video/mp4',
      })
      .addClass('builder-benefits__item-video')
      .appendTo(this.$visual)
      .get(0)
  }

  handlePlayIntersection = entries => {
    if (!this.isVisible(entries[0])) return

    this.video.currentTime = 0
    this.video.play()
    this.wasPlayed = true
  }

  handleLoadIntersection = entries => {
    if (!this.isVisible(entries[0])) return
    if (!this.wasPlayed) this.video.load()
    this.loadObserver.disconnect()
  }

  isVisible(entry = {}) {
    return entry.isIntersecting || entry.intersectionRatio > 0
  }
}

// ---
// Init
// ---
ready(function () {
  $('.builder-benefits__item').each(function () {
    new BuilderBenefit(this)
  })
})
