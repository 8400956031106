import $ from 'jquery'
import { ready } from 'shared/ready'
import imagesLoaded from 'imagesloaded'

class BuilderHeroResumes {
  constructor(node) {
    this.$el = $(node)
    this.$lists = this.$el.find('.builder-hero__resumes-list')

    // Calc proper `transition-delay` for each resume preview
    this.$lists.each((columnIndex, column) => {
      $(column)
        .find('.builder-hero__resume')
        .each((index, el) => {
          const delay = columnIndex * 0.15 + index * 0.175
          $(el)
            .css('transition-delay', `${delay}s`)
            .find('img')
            .css('transition-delay', `${delay + 0.075}s`)
        })
    })

    // Display images when they're loaded
    imagesLoaded(this.$el.find('img:visible'), e => {
      this.$el.addClass('builder-hero__resumes--loaded')
    })
  }
}

// ---
// Init
// ---
ready(function () {
  $('.builder-hero__resumes').each(function () {
    new BuilderHeroResumes(this)
  })
})
