import $ from 'jquery'
import { ready } from 'shared/ready'

class AboutUsHero {
  constructor(node) {
    this.$el = $(node)
    this.$visual = this.$el.find('.about__hero-video')
    this.videoSrc = this.$visual.data('video-src')
    this.video = this.createVideoNode()
    this.wasPlayed = false

    $(this.video).one('canplay', () => {
      $(this.video).addClass('about__hero-item-video--ready')
    })

    this.video.loop = true
    this.video.play()
  }

  createVideoNode() {
    return $(`<video muted="muted" preload="none" playsinline />`)
      .attr({
        src: this.videoSrc,
        type: 'video/mp4',
      })
      .addClass('about__hero-item-video')
      .appendTo(this.$visual)
      .get(0)
  }
}

// ---
// Init
// ---
ready(function () {
  $('.about__hero').each(function () {
    new AboutUsHero(this)
  })
})
