import $ from 'jquery'
import { ready } from 'shared/ready'
import throttle from '../utils/throttle'
import debounce from 'lodash/debounce'
import ScrollableSlideShow from './blogScrollableSlideShow'
import { trackInternalEvent } from '@rio/tracking'

class BlogAside {
  constructor($el) {
    this.$el = $el
    if (!this.$el.length) return

    new ScrollableSlideShow(this.$el.parent(), {
      contentSelector: '.blog-aside',
      itemSelector: '.blog-aside__item',
    })
    this.handleBlogAsideCtaVisibility()
    this.wideElements = $('.js-wide-element, .image--large, .wide-element').toArray()
    if (!this.wideElements.length) return

    this.throttledScrollHandler = throttle(this.checkIntersections.bind(this), 100)
    this.debouncedResizeHandler = debounce(this.checkScreenSize.bind(this), 100)

    window.addEventListener('resize', this.debouncedResizeHandler)

    this.checkScreenSize()
  }

  handleScroll(removeCtaBannerExamples) {
    const blogAsideCta = document.getElementById('blog-aside-cta')
    if (blogAsideCta && removeCtaBannerExamples === true) {
      const mainContentHeight = $('.blog-post__content-main-wrapper').height()
      let rightPanelHeight = mainContentHeight * (70 / 100)
      $('#blog-post-content-right').css({ height: rightPanelHeight })
    }
  }

  handleBlogAsideCtaVisibility() {
    let removeCtaBannerExamples = $('#remove-cta-banner-value_examples').text()
    if (removeCtaBannerExamples) {
      removeCtaBannerExamples = removeCtaBannerExamples === 'true'
      trackInternalEvent('cta_banner_examples', { saw_cta_banner: !removeCtaBannerExamples })
      window.addEventListener('scroll', this.handleScroll(removeCtaBannerExamples))
    }
  }

  checkScreenSize() {
    const isDesktop = window.innerWidth > 1023

    if (isDesktop && !this.isMounted) {
      window.addEventListener('scroll', this.throttledScrollHandler, { passive: true })
      this.isMounted = true
    } else if (!isDesktop && this.isMounted) {
      window.removeEventListener('scroll', this.throttledScrollHandler)
      this.isMounted = false
    }
  }

  checkIntersections() {
    const PADDING = 32
    const rect1 = this.$el[0].getBoundingClientRect()
    const hasOverlap = this.wideElements.some(el => {
      const rect2 = el.getBoundingClientRect()
      return !(
        rect1.right + PADDING < rect2.left ||
        rect1.left > rect2.right + PADDING ||
        rect1.bottom + PADDING < rect2.top ||
        rect1.top > rect2.bottom + PADDING
      )
    })

    if (hasOverlap) {
      this.$el.css({ opacity: 0 })
    } else {
      this.$el.css({ opacity: 1 })
    }
  }
}

// ---
// Init
// ---
ready(function () {
  $('.blog-aside').each((_, el) => {
    new BlogAside($(el))
  })
})
