import $ from 'jquery'
import { ready } from 'shared/ready'

import ChangeCountryModal from '../components/changeCountryModal'
import InterviewModal from '../components/interviewModal'
import CookieSettingsModal from '../components/cookie/cookieSettingsModal'

let modals = {}

ready(() => {
  modals.changeCountryModal = new ChangeCountryModal($('.modal--country-modal'))
  modals.interviewModal = new InterviewModal($('.modal--interview-modal'))
  modals.cookieSettingsModal = new CookieSettingsModal($('.modal--cookie-settings-modal'))
})

export default modals
