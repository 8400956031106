import $ from 'jquery'
import Clipboard from 'clipboard'

export default class CopyableComponent {
  constructor(copyableNode) {
    if (!Clipboard.isSupported()) return

    const $el = $(copyableNode)

    const $copyableButton = $el.find('.copyable__button')
    const $copyableTooltip = $el.find('.copyable__tooltip')

    const clipboard = new Clipboard($copyableButton.get(0))

    clipboard.on('success', () => {
      $copyableTooltip.css({ opacity: 1 })
      setTimeout(() => $copyableTooltip.css({ opacity: 0 }), 1000)
    })
  }
}
