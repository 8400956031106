/*
  This helps fix the VW unit considering the scrollbar width.
  Use in css: {
    width: calc(var(--vw) * 100);
  }
*/
function setVw() {
  let vw = document.documentElement.clientWidth / 100
  document.documentElement.style.setProperty('--vw', `${vw}px`)
}

setVw()
window.addEventListener('resize', setVw)
