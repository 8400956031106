import $ from 'jquery'
import { ready } from 'shared/ready'
import mediaQueries from '../support/mediaQueries'
import VisibilityObserver from '../support/VisibilityObserver'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'

class TrustpilotSlider {
  constructor(node) {
    this.$el = $(node)

    new VisibilityObserver(this.$el, {
      rootMargin: '50% 0% 50% 0%',
      callback: this.initializeSlider,
    })
  }

  initializeSlider = () => {
    this.$reviewsSlider = this.$el.find('.trustpilot-overview__reviews')
    this.$buttonLeft = this.$el.find('.trustpilot-overview__reviews-button--left')
    this.$buttonRight = this.$el.find('.trustpilot-overview__reviews-button--right')
    this.$progressBar = this.$el.find('.trustpilot-overview__reviews-bar')
    this.$progressLine = this.$el.find('.trustpilot-overview__reviews-bar-line')
    this.$progressLineWrapper = this.$el.find('.trustpilot-overview__reviews-bar-line-wrapper')

    // Handlers
    this.$buttonLeft.on('click', () => this.$reviewsSlider.slick('slickPrev'))
    this.$buttonRight.on('click', () => this.$reviewsSlider.slick('slickNext'))
    window.addEventListener('resize', this.handleWindowResize.bind(this))

    this.$reviewsSlider.slick(this.currentSlickOptions)

    this.$reviewsSlider.on('beforeChange', this.handleChangeSlide)

    this.updateProgressLineDimensions()
    this.$el.addClass('trustpilot-overview--ready')
  }

  get currentSlickOptions() {
    return {
      draggable: false,
      dots: false,
      infinite: true,
      speed: 250,
      slidesToShow: this.countOfVisibleItems,
      slidesToScroll: 1,
      centerMode: false,
      initialSlide: 0,
      variableWidth: true,
      variableHeight: true,
      lazyLoad: 'progressive',
      nextArrow: null,
      prevArrow: null,
    }
  }

  get countOfVisibleItems() {
    const currentMediaQueries = mediaQueries()
    return currentMediaQueries.isTablet ? 2 : 3
  }

  get countOfMoves() {
    const countOfItems = this.$reviewsSlider.slick('getSlick').slideCount
    return countOfItems - 1
  }

  get progressLineWidth() {
    const barWidth = this.$progressBar.width()
    return barWidth / (this.countOfMoves + 1)
  }

  // Detect the direction in edge cases:
  // when we are on the first slide and goes to the left
  // or when we are on the last slide and goes to the right
  detectEdgeCases(currentSlide, nextSlide) {
    const slideCount = this.$reviewsSlider.slick('getSlick').slideCount
    const isCurrentSlideLast = currentSlide === slideCount - 1
    const isCurrentSlideFirst = currentSlide === 0
    const isNextSlideLast = nextSlide === slideCount - 1
    const isNextSlideFirst = nextSlide === 0

    if (isCurrentSlideFirst && isNextSlideLast) return { leftEdgeCase: true }
    if (isCurrentSlideLast && isNextSlideFirst) return { rightEdgeCase: true }
    return {}
  }

  handleChangeSlide = (event, slick, currentSlide, nextSlide) => {
    const edgeCases = this.detectEdgeCases(currentSlide, nextSlide)
    this.setProgressLinePosition(currentSlide, nextSlide, edgeCases)
  }

  handleWindowResize(e) {
    this.$reviewsSlider.slick('slickSetOption', this.currentSlickOptions, true)
    this.$reviewsSlider.slick('refresh')
    this.updateProgressLineDimensions()
  }

  updateProgressLineDimensions = () => {
    this.$progressLine.css('width', `${this.progressLineWidth}px`)
    this.$progressLine
      .eq(0)
      .css('transform', `translateX(${-this.progressLineWidth * (this.countOfMoves + 1)}px)`)

    this.$progressLine.eq(1).css('transform', `translateX(0px)`)

    this.$progressLine
      .eq(2)
      .css('transform', `translateX(${this.progressLineWidth * (this.countOfMoves + 1)}px)`)
    this.setProgressLinePosition()
  }

  applyLineTransition(position, instant = false) {
    this.$progressLineWrapper.css({
      transition: instant ? 'none' : 'transform 0.2s',
      transform: `translateX(${position}px)`,
    })
  }

  setProgressLinePosition = (oldIndex, currentIndex, options = {}) => {
    const { leftEdgeCase, rightEdgeCase } = options
    this.$progressLineWrapper.css('transition', 'transform 0.2s ease')

    // If it's the end of the list and we push to the right
    if (rightEdgeCase) {
      this.applyLineTransition(this.progressLineWidth * (this.countOfMoves + 1))
      return setTimeout(() => this.applyLineTransition(0, true), 200)
    }

    // If it's the beginning of the list and we push to the left
    if (leftEdgeCase) {
      this.applyLineTransition(-this.progressLineWidth)
      return setTimeout(
        () => this.applyLineTransition(this.progressLineWidth * this.countOfMoves, true),
        200,
      )
    }

    this.applyLineTransition(this.progressLineWidth * currentIndex)
  }
}

ready(function () {
  $('.trustpilot-overview').each(function () {
    new TrustpilotSlider(this)
  })
})
