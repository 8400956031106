import BaseModal from './baseModal'

const LS_KEY_ACTIVATED = 'INTERVIEW_MODAL_ACTIVATED'

class InterviewModal extends BaseModal {
  constructor($root) {
    super($root)
    if (!$root || !$root.length) return

    // don't activate the modal more than once
    if (!this.shouldActivateModal()) return

    window.addEventListener('scroll', this.handleScroll.bind(this), { passive: true })

    $root.on('click', '.interview-modal__skip', this.close.bind(this))
    $root.on('click', '.interview-modal__book', this.close.bind(this))

    // set up a deadline timer
    this.deadlineTimer = setTimeout(this.triggerModal.bind(this), 15000)
  }

  shouldActivateModal() {
    try {
      return !localStorage.getItem(LS_KEY_ACTIVATED)
    } catch (_error) {
      return false
    }
  }

  handleScroll() {
    if (this.scrollTriggered) return

    const max = document.body.scrollHeight - window.innerHeight
    const current = window.pageYOffset
    const scrollPercentage = current / max

    if (scrollPercentage > 0.1) {
      this.scrollTriggered = true
      this.secondTimer = setTimeout(this.triggerModal.bind(this), 2000)
    }
  }

  triggerModal() {
    clearTimeout(this.deadlineTimer)
    clearTimeout(this.secondTimer)

    if (this.triggered) return
    this.triggered = true

    try {
      localStorage.setItem(LS_KEY_ACTIVATED, 'true')
    } catch (_error) {}

    this.open()
  }
}
export default InterviewModal
