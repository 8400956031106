import $ from 'jquery'
import { ready } from 'shared/ready'
import VisibilityObserver from '../support/VisibilityObserver'

class HomeLetters {
  constructor(node) {
    this.$el = $(node)

    new VisibilityObserver(this.$el, {
      rootMargin: '50% 0% 50% 0%',
      callback: this.initialize,
    })
  }

  initialize = () => {
    let cardsHtml = ''

    for (let i = 1; i <= 14; i++) {
      cardsHtml += `
        <div class="home-letters__card home-letters__card--${i}">
          <div class="home-letters__card-main"></div>
        </div>
      `
    }

    this.$el.prepend(cardsHtml)
  }
}

ready(function () {
  $('.home-letters').each(function () {
    new HomeLetters(this)
  })
})
