import $ from 'jquery'
import { ready } from 'shared/ready'

// ---
// Account view
// ---
class Sponsorship {
  constructor($root, options = {}) {
    this.$root = $root
    this.$applySection = this.$root.find('.sponsorship__apply')
    this.$logoUploader = this.$root.find('#sponsor-logo-uploader')
    this.$uploadPreview = this.$root.find('.sponsorship__upload-preview')
    this.$uploadRegion = this.$root.find('.sponsorship__upload-region')
    this.$previewImage = this.$root.find('.sponsorship__upload-preview-image')
    this.$previewName = this.$root.find('.sponsorship__upload-preview-name')
    this.$checkboxes = this.$root.find('.checkbox__input')
    this.$submitButton = this.$root.find('.sponsorship__apply-form-button')
    this.$applyForm = this.$root.find('.sponsorship__apply-form')
    this.$applyFormContainer = this.$root.find('.sponsorship__apply-form-container')
    this.$applyResult = this.$root.find('.sposnorship__apply-result')

    this.$logoUploader.change(this.handleLogoInputChange)
    this.$root.find('.sponsorship__head-button').click(this.handleClickHeaderCta)
    this.$root.find('.sponsorship__upload-preview-button').click(this.handleUploadFileClick)
    this.$checkboxes.change(this.handleCheckboxChange)

    this.$applyForm.submit(this.showAfterApplyState)
  }

  handleLogoInputChange = changeEvent => {
    const input = changeEvent.currentTarget
    let fileName = ''

    if (input.files && input.files[0]) {
      fileName = input.files[0].name
      const reader = new FileReader()

      reader.onload = loadEvent => {
        this.showLogoPreview(loadEvent.target.result, fileName)
      }

      reader.readAsDataURL(input.files[0])
    }
  }

  handleUploadFileClick = () => {
    this.$logoUploader.trigger('click')
  }

  showLogoPreview = (src, fileName) => {
    this.$uploadPreview.addClass('is-visible')
    this.$uploadRegion.addClass('is-hidden')
    this.$previewImage.css('background-image', `url(${src})`)
    this.$previewName.text(fileName)
  }

  handleClickHeaderCta = () => {
    $('html, body').animate({
      scrollTop: this.$applySection.offset().top + 'px',
    })
  }

  handleCheckboxChange = () => {
    const showButton = this.$checkboxes.length === this.$checkboxes.filter(':checked').length

    this.$submitButton.toggleClass('button--disabled', !showButton)
  }

  showAfterApplyState = () => {
    this.handleClickHeaderCta()
    setTimeout(() => {
      this.$applyFormContainer.addClass('fade-out')
      this.$applySection.addClass('small-height')
      this.$applyResult.addClass('fade-in')
    }, 300)
  }
}

// ---
// Init
// ---
ready(function () {
  return new Sponsorship($('.container--sponsorship'))
})
