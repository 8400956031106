import $ from 'jquery'
import { ready } from 'shared/ready'

class BuilderResourcesGuide {
  constructor(node) {
    this.$el = $(node)
    this.$content = this.$el.find('.builder-resources__guide-content')
    this.$secondary = this.$el.find('.builder-resources__guide-secondary')

    $(window).on('resize load', this.updateContentOffset)
    this.updateContentOffset()
    this.$el.addClass('builder-resources__guide--ready')
  }

  updateContentOffset = () => {
    const offset = this.$secondary.height()
    const transform = `translateY(${offset}px)`
    this.$content.css({ transform })
  }
}

// ---
// Init
// ---
ready(function () {
  $('.builder-resources__guide').each(function () {
    new BuilderResourcesGuide(this)
  })
})
