/**
 * Lighter version of lodash/debounce
 * https://you-dont-need.github.io/You-Dont-Need-Lodash-Underscore/#/?id=_debounce
 */
export default function debounce(fn, wait) {
  let timeout

  return () => {
    let args = arguments

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      timeout = null
      fn.apply(this, args)
    }, wait)
  }
}
