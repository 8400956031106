import $ from 'jquery'
import { ready } from 'shared/ready'
import debounce from '../utils/debounce'

const $window = $(window)

/**
 * Implements resizing categories navigation in resume.io/blog
 */
class BlogTopScrollPanel {
  classes = {
    scrollWrapper: 'blog-main__scroll-panel',
    scrollInner: 'blog-main__scroll-panel-inner',
  }

  constructor($el) {
    // Init elements
    this.$scrollParent = $el
    this.$scrollPanel = this.$scrollParent.find(`.${this.classes.scrollWrapper}`)
    this.$scrollInner = this.$scrollParent.find(`.${this.classes.scrollInner}`)

    this.debouncedHandleResize = debounce(this.handleResize, 100)
    this.debouncedHandleResize()
    $window.on('resize', this.debouncedHandleResize)
  }

  handleResize = () => {
    const leftPos = this.$scrollParent.offset().left
    const viewPortWidth = $window.width()

    if (viewPortWidth < 1024) {
      this.$scrollPanel.css({ left: -leftPos, width: viewPortWidth })
      this.$scrollInner.css({ paddingLeft: leftPos, paddingRight: leftPos })
    } else {
      this.$scrollPanel.css({ width: '', left: '' })
      this.$scrollInner.css({ paddingLeft: '', paddingRight: '' })
    }
  }
}

// ---
// Init
// ---
ready(function () {
  $('.blog-main__top-box-nav').each(function () {
    new BlogTopScrollPanel($(this))
  })
})
