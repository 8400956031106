import $ from 'jquery'
import { ready } from 'shared/ready'
import { Swiper, Autoplay, EffectFade } from 'swiper'
import VisibilityObserver from '../support/VisibilityObserver'
import matchMediaQueries from '../support/mediaQueries'

Swiper.use([Autoplay, EffectFade])

class Showcase {
  constructor(node) {
    this.$el = $(node)
    this.$gallery = this.$el.find('.showcase__videos')

    // Prevent videos from being downloaded until we need them
    new VisibilityObserver(this.$gallery, {
      rootMargin: '20% 0% 20% 0%',
      callback: this.insertVideos,
    })
  }

  // Create video players/nodes
  insertVideos = () => {
    this.$slides = this.$gallery.find('.showcase__video')
    this.videos = []

    this.$slides.each((i, slideNode) => {
      const $slide = $(slideNode)
      const src = $slide.data('video-src')

      const $video = $(`<video muted="muted" preload="auto" type="video/mp4" playsinline />`)
      const video = $video.attr({ src }).appendTo($slide).get(0)

      this.videos.push(video)
    })

    // Once the block appears in the viewport for the first time,
    // call the slider initialization.
    new VisibilityObserver(this.$gallery, {
      callback: this.initializeSlider,
    })
  }

  // Read video durations from data attributes
  // to pre-configure the slider's autoplay and progress bar animations
  configureDurations = () => {
    this.$slides.each((i, slideNode) => {
      const $slide = $(slideNode)
      const duration = parseInt($slide.data('video-duration')) * 1000

      $slide.attr('data-swiper-autoplay', duration)
      this.navigation.setAnimationDuration(i, duration)
    })
  }

  // Delayed slider constructor call.
  // Reduces the duration of render blocking tasks during the initial page loading.
  initializeSlider = () => {
    const { isTablet } = matchMediaQueries()
    this.$navigation = this.$el.find('.showcase__features')

    this.navigation = new ShowcaseNavigation(this.$navigation.get(0), {
      onClick: index => this.swiper && this.swiper.slideToLoop(index),
    })

    this.configureDurations()

    this.swiper = new Swiper(this.$gallery.get(0), {
      loop: true,
      speed: 400,
      slidesPerView: 1,
      threshold: 15,
      slideClass: 'showcase__video',
      wrapperClass: 'showcase__videos-list',
      simulateTouch: isTablet,
      effect: isTablet ? 'slide' : 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        disableOnInteraction: false,
        waitForTransition: false,
      },
      pagination: {
        el: '.showcase__bullets',
        bulletClass: 'showcase__bullet',
        bulletActiveClass: 'showcase__bullet--active',
      },
    })

    this.swiper.on('slideChange', this.handleSlideChange)
    this.swiper.on('slideChangeTransitionEnd', this.handleSlideChangeEnd)
    this.handleSlideChange()
  }

  isSlideVisible = index => {
    const { slides, activeIndex, isBeginning, isEnd } = this.swiper

    // the slide displayed on the screen
    if (index === activeIndex) return true
    // the duplicate of the first slide (loop mode)
    if (isBeginning && index === slides.length - 1) return true
    // the duplicate of the last slide (loop mode)
    if (isEnd && index === 0) return true

    return false
  }

  handleSlideChange = () => {
    const { slides, realIndex } = this.swiper

    this.navigation.activate(realIndex)

    // start video playing on visible slides (the target one and its duplicate)
    slides.forEach((slide, index) => {
      if (!this.isSlideVisible(index)) return
      const [video] = slide.childNodes
      video.currentTime = 0
      video.play()
    })
  }

  handleSlideChangeEnd = () => {
    const { slides } = this.swiper

    // rewind videos while they are not visible
    slides.forEach((slide, index) => {
      if (this.isSlideVisible(index)) return
      const [video] = slide.childNodes
      video.currentTime = 0
    })
  }
}

class ShowcaseNavigation {
  constructor(node, options) {
    this.$el = $(node)
    this.$tabs = this.$el.find('.showcase__feature')
    this.$subtitles = this.$tabs.find('.showcase__feature-subtitle')
    this.$progressBars = this.$tabs.find('.showcase__feature-bar-progress')
    this.activeIndex = null

    this.updateDimensions()
    $(window).on('resize load', this.updateDimensions)

    this.$tabs.each((index, tabNode) => {
      $(tabNode).on('click', () => options.onClick(index))
    })
  }

  // Calculate container height via JS
  // because most of the tabs have absolute positioning on mobiles
  updateDimensions = () => {
    if (!matchMediaQueries().isTablet) return

    const tabHeights = this.$tabs.map((i, tab) => tab.scrollHeight).get()
    const maxHeight = Math.max(...tabHeights)
    this.$el.css('min-height', maxHeight)
  }

  setAnimationDuration = (index, duration) => {
    this.$progressBars.eq(index).css('animation-duration', `${duration}ms`)
  }

  activate = index => {
    if (this.activeIndex === index) return

    const { isTablet } = matchMediaQueries()

    // deactivate previous tab
    if (this.activeIndex !== null) {
      this.$tabs.eq(this.activeIndex).removeClass('showcase__feature--active')
      if (!isTablet) this.$subtitles.eq(this.activeIndex).slideUp(200)
    }

    // highlight selected tab
    this.$tabs.eq(index).addClass('showcase__feature--active')
    if (!isTablet) this.$subtitles.eq(index).slideDown(200)

    this.activeIndex = index
  }
}

ready(function () {
  $('.showcase').each((i, node) => {
    new Showcase(node)
  })
})
