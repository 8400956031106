import $ from 'jquery'
import { ready } from 'shared/ready'
import VisibilityObserver from '../support/VisibilityObserver'
import matchMediaQueries from '../support/mediaQueries'
import { Swiper, Pagination } from 'swiper'

Swiper.use([Pagination])

class BuilderHowSection {
  constructor(node) {
    // Elements
    this.$el = $(node)
    this.$steps = this.$el.find('.builder-how__steps')
    this.$visual = this.$el.find('.builder-how__visual')
    this.$animationWrapper = this.$el.find('.builder-how__visual-inner')
    this.$animation = this.$el.find('.builder-how__animation')

    new VisibilityObserver(this.$el, {
      rootMargin: '200% 0% 200% 0%',
      callback: this.initialize,
    })
  }

  initialize = () => {
    this.initializeAnimation()

    // Switching between slider and basic modes
    this.updateMode()
    $(window).on('resize', this.updateMode)
  }

  get isSlider() {
    return this.swiper && !this.swiper.destroyed
  }

  updateMode = () => {
    const { isPhone } = matchMediaQueries()

    if (isPhone && !this.isSlider) {
      this.buildSlider()
    } else if (!isPhone && this.isSlider) {
      this.swiper.destroy()
    }
  }

  buildSlider = () => {
    if (!matchMediaQueries().isPhone) return

    this.swiper = new Swiper(this.$steps.get(0), {
      slidesPerView: 'auto',
      slideClass: 'builder-how__step',
      wrapperClass: 'builder-how__steps-list',
      pagination: {
        el: '.builder-how__bullets',
        bulletClass: 'builder-how__bullet',
        bulletActiveClass: 'builder-how__bullet--active',
      },
    })

    this.swiper.on('slideChange', () => {
      if (this.animation) this.animation.seekTo(this.swiper.activeIndex)
    })
  }

  initializeAnimation = async () => {
    const { BuilderAnimation } = await import('./builderAnimation')
    this.animation = new BuilderAnimation(this.$animation.get(0), this.$visual.get(0))

    this.updateAnimation()
    $(window).on('resize', this.updateAnimation)
    this.$animation.addClass('builder-how__animation--ready')
  }

  updateAnimation = () => {
    const { isPhone } = matchMediaQueries()
    const width = this.$animationWrapper.width()
    const height = this.$animationWrapper.height()
    const scale = width / this.animation.baseWidth

    this.$animation.css('transform', `scale(${scale}) translateZ(0)`)
    this.$animationWrapper.css('top', `calc(50% - ${height / 2 - 30}px)`)

    this.animation.toggleScrollListener(!isPhone)
  }
}

ready(function () {
  $('.builder-how').each(function () {
    new BuilderHowSection(this)
  })
})
