/**
 * Lighter version of lodash/throttle
 * https://you-dont-need.github.io/You-Dont-Need-Lodash-Underscore/#/?id=_throttle
 */
export default function throttle(func, timeFrame) {
  let lastTime = 0

  return function (...args) {
    let now = Date.now()

    if (now - lastTime >= timeFrame) {
      func(...args)
      lastTime = now
    }
  }
}
