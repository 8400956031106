import $ from 'jquery'
import { ready } from 'shared/ready'
import mediaQueries from '../support/mediaQueries'
import VisibilityObserver from '../support/VisibilityObserver'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'

const A4_FACTOR = 0.71
const VIEW_MODE_WIDTH = 356
const VIEW_MODE_HEIGHT = 480
const TEXT_CONTENT_MARGIN = 96
const TEMPLATE_MIN_HEIGHT = 326
const SUM_TOP_BOTTOM_CONTAINER_MARGINS = 160
const TEMPLATE_SIDE_MARGIN = 32
const TEMPLATES_CONTAINER_HEIGHT = 760 // in case of bug in firefox

class TemplatesSlider {
  constructor($el) {
    if ($el.length === 0) return

    // Elements
    this.$el = $el
    this.$templatesSlider = this.$el.find('.resume-templates__slider')
    this.$templates = this.$el.find('.resume-templates__item').filter((i, item) => {
      // get the path to create template e.g. /app/create-resume?template=rirekisho
      const templatePath = $(item).find('.resume-templates__item-button').attr('href') ?? ''

      // Get all search params in the path
      const searchParams = new URLSearchParams(templatePath.split('?')[1])

      // Get template id from the param
      const templateId = searchParams.get('template')

      // Logic to hide Japanese templates on non-japanese websites
      const japaneseTemplates = ['rirekisho', 'shokumukeirekisho']
      // Filter Japanese templates for locales other than Japan
      const isLocaleJp =
        ['staging.rirekisho.jp', 'rirekisho.jp'].includes(window.location.hostname) ||
        Array.from(document.getElementsByTagName('footer')).some(item =>
          item.textContent.includes('Rirekisho.jp'),
        )
      if (!isLocaleJp && japaneseTemplates.includes(templateId)) {
        $(item).remove()
        return false
      }
      // TODO: Remove this condition after fixing ATS templates
      // Logic to hide ATS templates on japanese websites
      const ATSTemplates = ['athens', 'brussels', 'prague', 'shanghai']
      if (isLocaleJp && ATSTemplates.includes(templateId)) {
        $(item).remove()
        return false
      }
      return true
    })
    this.$templatesTextContent = this.$el.find('.resume-templates__content')
    this.$sliderContainer = this.$el.find('.resume-templates__slider-container')
    this.$sliderWrapper = this.$el.find('.resume-templates__slider-wrapper')
    this.$sliderButton = this.$el.find('.resume-templates__item-button')
    this.$sliderControls = this.$el.find('.resume-templates__slider-button')
    this.$templatePreview = this.$el.find('.resume-templates__item-preview')
    this.$templateNameContainer = this.$el.find('.resume-templates__item-name-container')
    this.$sliderLeftButtom = this.$el.find('.resume-templates__slider-button--left')
    this.$sliderRightButtom = this.$el.find('.resume-templates__slider-button--right')
    this.$arrows = this.$el.find('.resume-templates__slider-arrow-container')

    // Variables
    this.isChoosingMode = false
    this.templateHeight = 480
    this.templateWidth = 356
    this.viewModeSet = false
    this.currentMediaQueries = mediaQueries()
    this.isInitiated = false

    // Handlers
    this.$sliderLeftButtom.click(() => this.$templatesSlider.slick('slickPrev'))
    this.$sliderRightButtom.click(() => this.$templatesSlider.slick('slickNext'))
    this.$arrows.click(this.handleTemplateClick)

    window.addEventListener('resize', this.handleWindowResize)

    new VisibilityObserver(this.$el, {
      rootMargin: '100% 0% 100% 0%',
      callback: this.initSlider,
    })
  }

  getCurrentSlickOptions = () => {
    return {
      draggable: this.currentMediaQueries.isTablet,
      dots: this.isChoosingMode || this.currentMediaQueries.isTablet,
      dotsClass: 'resume-templates__slider-dot',
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: 0,
      initialSlide: 0,
      variableWidth: true,
      adaptiveHeight: true,
      focusOnSelect: true,
      nextArrow: null,
      prevArrow: null,
    }
  }

  initSlider = () => {
    this.insertSliderImages()
    this.$templatesSlider.slick(this.getCurrentSlickOptions())
    this.$el.find('.slick-slider').on('click', '.slick-slide', this.handleTemplateClick)
    this.isInitiated = true
    this.handleWindowResize()
    this.$el.addClass('resume-templates--ready')
  }

  insertSliderImages = () => {
    this.$templatePreview.each((i, item) => {
      item.src = $(item).attr('data-template-bg')
    })
  }

  setViewMode = () => {
    let scaleFactorX = VIEW_MODE_WIDTH / this.templateWidth
    let scaleFactorY = VIEW_MODE_HEIGHT / this.templateHeight

    if (scaleFactorX > 1 || scaleFactorY > 1) {
      scaleFactorX = 1
      scaleFactorY = 1
    }

    this.$sliderWrapper.css('transform', `scale(${scaleFactorX}, ${scaleFactorY})`)
    const distanceFromWrapperToContainerX =
      this.$sliderContainer.offset().left - this.$sliderWrapper.offset().left

    const distanceFromContainerToTemplateY =
      this.$templates.eq(0).offset().top - this.$sliderWrapper.offset().top

    let translateDistance =
      this.$templatesTextContent.offset().left +
      this.$templatesTextContent.width() -
      distanceFromWrapperToContainerX +
      TEXT_CONTENT_MARGIN

    const translateDistanceY =
      this.$templatesTextContent.offset().top -
      this.$sliderWrapper.offset().top -
      distanceFromContainerToTemplateY

    this.$sliderWrapper.css(
      'transform',
      `scale(${scaleFactorX}, ${scaleFactorY}) ` +
        `translateX(${translateDistance}px) ` +
        `translateY(${translateDistanceY}px)`,
    )

    this.updateSlickWithOptions(this.getCurrentSlickOptions())

    setTimeout(() => {
      this.$sliderWrapper.css('transition', `opacity 0.1s ease-in`)
      this.$sliderWrapper.addClass('is-visible')
      this.viewModeSet = true
    }, 100)
  }

  updateSlickWithOptions = options => {
    this.$templatesSlider.slick('slickSetOption', options, true)
    this.$templatesSlider.slick('refresh')
  }

  handleWindowResize = () => {
    if (!this.isInitiated) return

    this.currentMediaQueries = mediaQueries()
    if (this.currentMediaQueries.isTablet) {
      this.setSliderChoosingModeOff()
      this.updateSlickWithOptions(this.getCurrentSlickOptions())
      return
    }

    if (!this.viewModeSet || this.isChoosingMode) {
      this.templateHeight = Math.max(
        TEMPLATE_MIN_HEIGHT,
        TEMPLATES_CONTAINER_HEIGHT - SUM_TOP_BOTTOM_CONTAINER_MARGINS,
      )
      this.templateWidth = this.templateHeight * A4_FACTOR

      if (window.innerWidth < this.templateWidth * 3 + TEMPLATE_SIDE_MARGIN * 4) {
        this.templateWidth = (window.innerWidth - TEMPLATE_SIDE_MARGIN * 4) / 3
        this.templateHeight = this.templateWidth / A4_FACTOR
      }

      this.$sliderContainer.width(this.templateWidth * 3 + TEMPLATE_SIDE_MARGIN * 4)

      this.$templates.width(this.templateWidth)
      this.$templates.height(this.templateHeight)
      this.updateSlickWithOptions(this.getCurrentSlickOptions())
    }

    if (!this.isChoosingMode) {
      this.setViewMode()
    }
  }

  handleTemplateClick = e => {
    if (!this.isChoosingMode && !this.currentMediaQueries.isTablet) {
      e.preventDefault()
      e.stopPropagation()
      this.setSliderChoosingModeOn()
    }
  }

  showContentText = () => {
    this.$templatesTextContent.css('transition', 'none')
    this.$templatesTextContent.css('transform', `translateX(0)`)
    this.$templatesTextContent.css('opacity', 1)
  }

  hideContentText = () => {
    const distance = this.$templatesTextContent.offset().left + this.$templatesTextContent.width()
    this.$templatesTextContent.css('transform', `translateX(${-distance}px)`)
    this.$templatesTextContent.css('opacity', 0)
  }

  setSliderChoosingModeOff = () => {
    this.showContentText()
    this.$sliderWrapper.css('transition', 'none')
    this.$sliderWrapper.css('transform', 'none')
    this.$sliderWrapper.removeClass('is-choosing-mode-on')
    this.$sliderButton.removeClass('resume-templates__item-button--visible')
    this.$sliderControls.removeClass('is-visible')
    this.$templatePreview.removeClass('is-choosing-mode-on')
    this.$templateNameContainer.removeClass('is-visible')
    this.isChoosingMode = false
  }

  setSliderChoosingModeOn = () => {
    this.hideContentText()
    this.$sliderWrapper.css('transition', `transform 0.3s ease`)
    this.$sliderWrapper.css('transform', `scale(1, 1) translate(0, 0)`)
    this.$sliderWrapper.addClass('is-choosing-mode-on')
    this.$templatePreview.addClass('is-choosing-mode-on')
    this.$templateNameContainer.addClass('is-visible')
    this.isChoosingMode = true
    this.updateSlickWithOptions(this.getCurrentSlickOptions())

    setTimeout(() => {
      this.$sliderButton.addClass('resume-templates__item-button--animated')
      this.$sliderButton.addClass('resume-templates__item-button--visible')
      this.$sliderControls.addClass('is-visible')
      setTimeout(
        () => this.$sliderButton.removeClass('resume-templates__item-button--animated'),
        200,
      )
    }, 200)
  }
}

ready(function () {
  new TemplatesSlider($('.resume-templates'))
})
