import $ from 'jquery'
import { ready } from 'shared/ready'

class TemplatesCard {
  constructor(root) {
    this.$card = $(root)
    this.$colors = this.$card.find('.templates-card__colors-item')

    if (this.$colors.length === 0) return

    this.$card.one('mouseenter', () => {
      this.makeInteractive()
      this.$card.addClass('templates-card--was-hovered')
    })
  }

  injectSecondaryImages = () => {
    this.$card.find('[data-img-src]').each((i, el) => {
      const $el = $(el)
      const src = $el.data('img-src')
      const srcset = $el.data('img-srcset')
      const width = $el.data('img-width')
      const height = $el.data('img-height')
      const color = $el.data('color')

      const $img = $('<img class="templates-card__preview-image" />').attr({
        src,
        srcset,
        width,
        height,
        loading: 'lazy',
        'data-color': color,
      })

      $el.replaceWith($img)
    })
  }

  makeInteractive = () => {
    this.injectSecondaryImages()

    this.$images = this.$card.find('.templates-card__preview-image')

    this.colors = this.serializeColors(this.$colors, this.$images)
    this.activeHex = this.$colors.filter('.templates-card__colors-item--active').data('color')

    this.$colors.on('mouseenter', this.handleColorSelection)
  }

  serializeColors = ($colors, $images) => {
    const data = {}

    $colors.each((i, el) => {
      const $control = $(el)
      const hex = $control.data('color')
      const $image = $images.filter(`[data-color="${hex}"]`)
      data[hex] = { $control, $image }
    })

    return data
  }

  handleColorSelection = event => {
    const hex = $(event.target).data('color')
    this.showColor(hex)
  }

  toggleColorState = (hex, state) => {
    const color = this.colors[hex]
    color.$control.toggleClass('templates-card__colors-item--active', state)
    color.$image.toggleClass('templates-card__preview-image--active', state)
  }

  showColor = hex => {
    if (hex === this.activeHex) return

    this.toggleColorState(this.activeHex, false)
    this.toggleColorState(hex, true)
    this.activeHex = hex
  }
}

// ---
// Init
// ---
ready(function () {
  $('.templates-grid__cell').each(function (index) {
    // get the path to create template e.g. /app/create-resume?template=rirekisho
    const templatePath = $(this).find('.templates-card').attr('href') ?? ''

    // Get all search params in the path
    const searchParams = new URLSearchParams(templatePath.split('?')[1])

    // Get template id from the param
    const templateId = searchParams.get('template')

    // Logic to hide Japanese templates on non-japanese websites
    const japaneseTemplates = ['rirekisho', 'shokumukeirekisho']

    // to check japanese website
    const isLocaleJp =
      ['staging.rirekisho.jp', 'rirekisho.jp'].includes(window.location.hostname) ||
      Array.from(document.getElementsByTagName('footer')).some(item =>
        item.textContent.includes('Rirekisho.jp'),
      )
    if (!isLocaleJp && japaneseTemplates.includes(templateId)) {
      $(this).remove()
    }

    // TODO: Remove this condition after fixing ATS templates
    const ATSTemplates = ['athens', 'brussels', 'prague', 'shanghai']
    if (isLocaleJp && ATSTemplates.includes(templateId)) {
      $(this).remove()
    }
    new TemplatesCard(this)
  })
})
