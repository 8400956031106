import $ from 'jquery'
import mediaQueries from '../../support/mediaQueries'
import VisibilityObserver from '../../support/VisibilityObserver'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'

export default class TemplateSliderComponent {
  constructor($el) {
    this.currentMediaQueries = mediaQueries()

    this.$el = $el
    this.$slider = this.$el.find('.template-slider__gallery-items')
    this.$sliderItems = this.$el.find('.template-slider__gallery-item')
    this.$colors = this.$el.find('.template-slider__colors')
    this.$nextButton = this.$el.find('.template-slider__button-next')
    this.$prevButton = this.$el.find('.template-slider__button-previous')

    this.initSlider()
    this.initListeners()

    new VisibilityObserver(this.$el, {
      threshold: 0.5,
      callback: this.triggerSlider,
    })
  }

  getCurrentSlickOptions = () => {
    return {
      draggable: this.currentMediaQueries.isTablet,
      dots: null,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: 0,
      initialSlide: 0,
      variableWidth: true,
      adaptiveHeight: false,
      focusOnSelect: true,
      nextArrow: null,
      prevArrow: null,
    }
  }

  showColorsForSlide = index => {
    const $currentColors = $(`[data-template="template-${index}"]`)
    this.$colors.removeClass('template-slider__colors--visible')
    $currentColors.addClass('template-slider__colors--visible')
  }

  initSlider = () => {
    this.showColorsForSlide(0)
    this.$slider.slick(this.getCurrentSlickOptions())
  }

  initListeners = () => {
    this.$prevButton.on('click', () => this.$slider.slick('slickPrev'))
    this.$nextButton.on('click', () => this.$slider.slick('slickNext'))
    this.$slider.on('afterChange', (e, slick, index) => this.showColorsForSlide(index))
  }

  triggerSlider = () => {
    this.$el.addClass('template-slider--triggered')
    this.$slider.slick('slickNext')
  }
}
