import $ from 'jquery'
import { ready } from 'shared/ready'
import EmbedComponent from './richContentBlocks/embed'
import CopyableComponent from './richContentBlocks/copyable'
import ResourcesComponent from './richContentBlocks/resources'
import TemplateSliderComponent from './richContentBlocks/templateSlider'
import CompareResumesComponent from './richContentBlocks/compareResumes'
import { trackMarketingEvent } from '@rio/tracking'
import SalariesComponent from './richContentBlocks/salaries'

class BlogPostView {
  constructor($blogPost) {
    if (!$blogPost.length) return

    $blogPost.find('.resources').each(function () {
      new ResourcesComponent(this)
    })

    $blogPost.find('.copyable').each(function () {
      new CopyableComponent($(this))
    })

    $blogPost.find('.template-slider').each(function () {
      new TemplateSliderComponent($(this))
    })

    $blogPost.find('.embed-code').each(function () {
      new EmbedComponent($(this))
    })

    $blogPost.find('.compare-resumes').each(function () {
      new CompareResumesComponent($(this))
    })

    $blogPost.find('.salaries').each(function () {
      new SalariesComponent($(this))
    })

    $blogPost.find('.post-cta').click(() => trackMarketingEvent('Blog Post', 'CTA click'))
    $blogPost
      .find('.blog-post__content-main-wrapper')
      .find('a[href$="https://resume.io/create-resume"]')
      .click(() => trackMarketingEvent('Blog Post', 'Content CTA click'))

    this.$shareButtons = $blogPost.find('.blog-post__sharing-item')
    this.$shareButtons.on('click', this.handleShareClick)

    this.isRated = false
    this.$ratingStars = $blogPost.find('.blog-post-footer__rating-star')
    this.$ratingAmount = $blogPost.find('.blog-post-footer__rating-amount')
    this.inactiveStarsCount = $blogPost.find('.blog-post-footer__rating-star--inactive').length

    this.$ratingStars.on('mouseover', this.handleStarsHover)
    this.$ratingStars.on('mouseleave', this.handleStarsBlur)
    this.$ratingStars.on('click', this.handleStarClick)
  }

  handleShareClick() {
    const width = 600
    const height = 400
    const left = window.screen.width / 2 - width / 2
    const top = window.screen.height / 2 - height / 2

    window.open(
      this.href,
      '',
      `menubar=no,
      toolbar=no,
      resizable=yes,
      scrollbars=yes,
      height=${height},
      width=${width},
      left=${left},
      top=${top}`,
    )
    return false
  }

  handleStarsHover = () => {
    if (this.isRated) return

    this.$ratingStars.addClass('blog-post-footer__rating-star--inactive')
  }

  handleStarsBlur = () => {
    if (this.isRated) return

    this.$ratingStars.each((i, el) => {
      if (i === 0 && this.inactiveStarsCount) return
      $(el).removeClass('blog-post-footer__rating-star--inactive')
    })
  }

  handleStarClick = event => {
    if (this.isRated) return

    this.isRated = true
    const reversedStars = this.$ratingStars.get().reverse()
    const starIndex = reversedStars.indexOf(event.currentTarget)

    reversedStars.forEach((el, i) => {
      if (i > starIndex) return
      $(el).removeClass('blog-post-footer__rating-star--inactive')
    })

    this.$ratingStars.removeClass('blog-post-footer__rating-star--with-hover')

    const amount = parseInt(this.$ratingAmount.data('amount')) + 1
    const text = this.$ratingAmount.data('template').replace('#AMOUNT#', amount)
    this.$ratingAmount.text(text)
  }
}

// ---
// Init
// ---
ready(function () {
  return new BlogPostView($('.blog-post'))
})
