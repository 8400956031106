import $ from 'jquery'
import { ready } from 'shared/ready'

class Anchor {
  constructor(anchor) {
    this.$anchor = $(anchor)
    const selector = this.$anchor.attr('href') || this.$anchor.data('anchor')
    this.$target = $(selector)

    this.$anchor.on('click', this.handleClick)
  }

  handleClick = e => {
    e.preventDefault()

    const offset = $('.nav').height()
    const scrollTop = this.$target.offset().top - offset
    $('html, body').animate({ scrollTop }, 400)
  }
}

ready(function () {
  $('[data-anchor]').each(function () {
    new Anchor(this)
  })
})
