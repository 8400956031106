import $ from 'jquery'
import { ready } from 'shared/ready'
import { trackInternalEvent } from '@rio/tracking'
import debounce from 'lodash/debounce'

const trackingDebounced = debounce((query, exampleType) => {
  trackInternalEvent(`search_${exampleType}_example`, { query })
}, 200)

class ExamplesSearch {
  constructor(node) {
    this.$el = $(node)
    this.$field = this.$el.find('.examples-search__input')
    this.$dropdown = this.$el.find('.examples-search__dropdown')
    this.$content = this.$el.find('.examples-search__content')

    this.isReady = false
    this.isFieldFocused = false
    this.maxResults = 20
    this.exampleType = $('.examples-root').data('example-type')

    this.$field.on('focus', this.handleSearchFocus.bind(this))
  }

  prepare() {
    this.searchEntries = this.serializeEntries()

    this.$content.on('mousedown', 'a', this.handleLinkClick.bind(this))
    this.$field.on('change keyup paste', this.renderDropdown.bind(this))
    this.$field.on('blur', this.handleSearchBlur.bind(this))

    this.isReady = true
  }

  serializeEntries() {
    const entries = $('.js-example').map((i, el) => ({
      title: $(el).data('title'),
      url: $(el).attr('href'),
    }))

    return entries
      .get()
      .filter((val, i, arr) => arr.findIndex(item => item.title === val.title) === i)
  }

  handleLinkClick(event) {
    event.stopPropagation()
    return false
  }

  handleSearchBlur(e) {
    this.isFieldFocused = false
    this.renderDropdown(e)
  }

  handleSearchFocus(e) {
    this.isFieldFocused = true
    trackInternalEvent(`open_${this.exampleType}_example_search`)
    this.renderDropdown(e)
  }

  renderDropdown(e) {
    if (!this.isReady) this.prepare()

    const query = this.$field.val().trim().toLowerCase()
    this.$content.empty()

    const matches = this.searchEntries
      .filter(entry => (query ? entry.title.toLowerCase().indexOf(query) !== -1 : true))
      .slice(0, this.maxResults)

    if (e.type === 'keyup') {
      trackingDebounced(query, this.exampleType)
    }

    matches.forEach(entry => {
      this.$content.append(`
          <a
            class="examples-search__item"
            href="${entry.url}"
            data-internal-event='${JSON.stringify({
              name: `click_${this.exampleType}_example_search_result`,
            })}'
          >
            <span class="examples-search__item-name">${entry.title}</span>
          </a>
        `)
    })

    const isActive = this.isFieldFocused && matches.length > 0
    this.$dropdown.toggleClass('is-active', isActive)
    this.$content.scrollTop(0)
  }
}

// ---
// Init
// ---
ready(function () {
  document.querySelectorAll('.examples-search').forEach(node => {
    new ExamplesSearch(node)
  })
})
