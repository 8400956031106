import $ from 'jquery'
import { ready } from 'shared/ready'
import matchMediaQueries from '../support/mediaQueries'
import VisibilityObserver from '../support/VisibilityObserver'
import { Swiper, Lazy, Navigation, Scrollbar } from 'swiper'

Swiper.use([Lazy, Navigation, Scrollbar])

class DocumentExamplesComponent {
  constructor(node) {
    this.$el = $(node)

    new VisibilityObserver(this.$el, {
      rootMargin: '50% 0% 50% 0%',
      callback: this.initializeSlider,
    })
  }

  initializeSlider = () => {
    this.$gallery = this.$el.find('.document-examples__gallery')
    this.$slider = this.$el.find('.document-examples__slider')

    this.infinite = !matchMediaQueries().isPhone
    this.$gallery.toggleClass('document-examples__gallery--infinite', this.infinite)

    new Swiper(this.$slider.get(0), {
      loop: this.infinite,
      slidesPerView: 'auto',
      threshold: 15,
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 8,
        elementClass: 'document-examples__item-image',
      },
      navigation: {
        prevEl: '.document-examples__nav--prev',
        nextEl: '.document-examples__nav--next',
      },
      scrollbar: {
        el: '.document-examples__scrollbar',
        dragClass: 'document-examples__scrollbar-handle',
        dragSize: 100,
      },
      slideClass: 'document-examples__item',
      slideActiveClass: 'document-examples__item--active',
      wrapperClass: 'document-examples__list',
    })

    this.$el.addClass('document-examples--ready')
  }
}

ready(function () {
  $('.document-examples').each((i, node) => {
    new DocumentExamplesComponent(node)
  })
})
