import { trackMarketingEvent, trackInternalEvent } from '@rio/tracking'

const clickTracker = e => {
  const link = e.target.closest('a')

  if (
    !link ||
    (!link.hasAttribute('data-marketing-event') && !link.hasAttribute('data-internal-event'))
  )
    return

  const { marketingEvent, internalEvent } = Object.keys(link.dataset).reduce((acc, key) => {
    if (key === 'marketingEvent' || key === 'internalEvent') {
      acc[key] = JSON.parse(link.dataset[key])
    }
    return acc
  }, {})

  const { name: internalEventName, ...internalEventPayload } = internalEvent || {}

  // Catching the new window or tab opening action
  if (e.ctrlKey || e.shiftKey || e.metaKey || e.button === 1 || e.target.target === '_blank') {
    // tracking event without waiting
    if (internalEvent) trackInternalEvent(internalEventName, internalEventPayload)
    if (marketingEvent) trackMarketingEvent(marketingEvent.category, marketingEvent.action)
    return
  }

  e.preventDefault()

  if (marketingEvent) {
    if (internalEvent) trackInternalEvent(internalEventName, internalEventPayload)
    trackMarketingEvent(marketingEvent.category, marketingEvent.action).then(() => {
      document.location = link.href
    })
  } else if (internalEvent) {
    trackInternalEvent(internalEventName, internalEventPayload)
    document.location = link.href
  }
}

document.body.addEventListener('click', clickTracker)
// Middle mouse click listener
document.body.addEventListener('auxclick', clickTracker)
