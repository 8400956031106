import $ from 'jquery'
import { ready } from 'shared/ready'

class PricingFaqView {
  constructor($el) {
    if ($el.length === 0) return

    const view = this
    view.$el = $el
    view.$items = $el.find('.pricing__faq-item')
    view.openIndex = -1

    view.$items.each(function (index) {
      $(this).on('click', () => view.toggle(index))
    })
  }

  open(index) {
    const $item = this.$items.eq(index)
    const $answer = $item.find('.pricing__faq-answer')
    $item.addClass('is-open')
    $answer.animate({ height: $answer.get(0).scrollHeight }, 300)
    this.openIndex = index
  }

  close(index) {
    const $item = this.$items.eq(index)
    $item.removeClass('is-open')
    $item.find('.pricing__faq-answer').animate({ height: 0 }, 300)
    this.openIndex = -1
  }

  toggle(index) {
    if (this.openIndex === index) {
      this.close(index)
    } else if (this.openIndex !== -1) {
      this.close(this.openIndex)
      this.open(index)
    } else {
      this.open(index)
    }
  }
}

// Init
ready(function () {
  new PricingFaqView($('.pricing__faq'))
})
