import $ from 'jquery'
import { ready } from 'shared/ready'
import Faq from '../components/faq'

class HomeView {
  constructor($el) {
    if ($el.length === 0) return

    // Elements
    this.$el = $el
    this.isLabelAnimated = false

    new Faq({
      root: $el.find('.home__faq'),
      classes: {
        seen: 'home__faq--seen',
        question: 'home__question',
        opened: 'home__question--opened',
        content: 'home__question-content',
      },
    })
  }

  handleResumeCount(data) {
    const localized = data.localized[this.$el.data('locale')]
    if (!localized) return

    const resumesToday = localized.today
    const resumesTotal = localized.total

    this.animateTextLabel(this.$el.find('.home__hero-counter-wrapper'), resumesToday)

    this.$el.find('.home__companies-stats-signups').text(resumesToday)
    this.$el.find('.home__companies-stats-resumes').text(resumesTotal)
  }

  animateTextLabel = ($label, content) => {
    if (!$label.length || this.isLabelAnimated || !document.hasFocus()) {
      return
    }

    this.isLabelAnimated = true

    if (!$label.hasClass('home__hero-animated-label')) {
      $label.addClass('home__hero-animated-label')
    }

    const $prevContent = $label.children(':first')
    $prevContent.addClass('is-out')

    const $newContent = $('<div>')
      .addClass('home__hero-counter-number')
      .text(content)
      .addClass('is-in')

    $label.prepend($newContent)

    $label.css({ width: $prevContent.outerWidth() })

    /* eslint-disable */
    // force element reflow
    getComputedStyle($newContent.get(0)).opacity
    getComputedStyle($label.get(0)).opacity
    /* eslint-enable */

    $label.css({ width: $newContent.outerWidth() })
    $newContent.addClass('is-in-active')

    $newContent.one('webkitTransitionEnd transitionend', () => {
      $prevContent.remove()
      $newContent.removeClass('is-in', 'is-in-active')
      this.isLabelAnimated = false
    })
  }
}

// ---
// Init
// ---
ready(function () {
  return new HomeView($('.home'))
})
