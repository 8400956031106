import $ from 'jquery'
import { ready } from 'shared/ready'
import { trackMarketingEvent } from '@rio/tracking'

class PartnerBanner {
  constructor(node) {
    node.addEventListener('click', function () {
      const eventLabel = $(node).data('company')
      trackMarketingEvent('Blog Post', 'Open Partner Website', { eventLabel })
    })
  }
}

ready(function () {
  $('.partner-banner').each(function () {
    new PartnerBanner(this)
  })
})
