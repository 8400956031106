import $ from 'jquery'
import { ready } from 'shared/ready'
import { Swiper, Autoplay, Lazy, EffectFade } from 'swiper'
import VisibilityObserver from '../support/VisibilityObserver'
import matchMediaQueries from '../support/mediaQueries'

Swiper.use([Autoplay, Lazy, EffectFade])

class HomeHow {
  constructor(node) {
    this.$el = $(node)
    this.$gallery = this.$el.find('.home-how__panes')
    this.$tabs = this.$el.find('.home-how__tab')
    this.$progressBars = this.$el.find('.home-how__tab-bar-progress')

    new VisibilityObserver(this.$gallery, {
      rootMargin: '50% 0% 50% 0%',
      callback: this.initializeSlider,
    })
  }

  initializeSlider = () => {
    const { isPhone } = matchMediaQueries()
    const autoplayDelay = isPhone ? 15000 : 20000

    this.swiper = new Swiper(this.$gallery.get(0), {
      loop: true,
      slidesPerView: 1,
      threshold: 15,
      slideClass: 'home-how__pane',
      wrapperClass: 'home-how__panes-list',
      simulateTouch: false,
      effect: isPhone ? 'slide' : 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: autoplayDelay,
        disableOnInteraction: false,
        waitForTransition: false,
      },
      lazy: {
        checkInView: true,
        loadPrevNext: true,
        loadPrevNextAmount: 1,
        loadOnTransitionStart: true,
        elementClass: 'home-how__pane',
      },
      pagination: {
        el: '.home-how__bullets',
        bulletClass: 'home-how__bullet',
        bulletActiveClass: 'home-how__bullet--active',
      },
      on: {
        slideChange: swiper => {
          this.$tabs.removeClass('home-how__tab--active')
          this.$tabs.eq(swiper.realIndex).addClass('home-how__tab--active')
        },
      },
    })

    this.$progressBars.css('animation-duration', `${autoplayDelay}ms`)

    this.$tabs.each((index, tabNode) => {
      $(tabNode).on('click', () => this.swiper.slideToLoop(index))
    })
  }
}

ready(function () {
  $('.home-how').each((i, node) => {
    new HomeHow(node)
  })
})
