import $ from 'jquery'
import { ready } from 'shared/ready'
import CookiePanel from './cookiePanel'
import CookieSettingsForm from './cookieSettingsForm'
import Cookiebar from './cookiebar'
import Cookies from 'js-cookie'
import { trackMarketingEvent } from '@rio/tracking'

class CookieController {
  constructor() {
    this.allAdditionalTypes = ['AnalyticalCookies', 'MarketingCookies']

    this.strictMode = document.body.hasAttribute('data-strict-cookie-policy')
    this.strictMode ? this.initializeStrictCookieControls() : this.initializeSimpleCookieControls()
  }

  initializeSimpleCookieControls() {
    const $cookiebar = $('.cookiebar')

    if (!$cookiebar.length) {
      return this.acceptAllCookies()
    }

    new Cookiebar($cookiebar, this)
  }

  initializeStrictCookieControls = () => {
    const $cookiePanel = $('.cookie-panel')

    if (!$cookiePanel.length) {
      return this.setEarlierAcceptedCookies()
    }

    const cookiePanel = new CookiePanel($cookiePanel, this)
    new CookieSettingsForm($('.cookie-settings-modal__form'), this, cookiePanel)
  }

  acceptCookies = types => {
    const acceptedTypes = ['EssentialCookies', ...types]

    if (!Cookies.get('cookie_consent')) {
      Cookies.set('cookie_consent', acceptedTypes, { expires: 365 })
    }

    if (types && types.length) {
      if (this.strictMode) {
        types.forEach(type => {
          if (this.allAdditionalTypes.indexOf(type) >= 0) {
            this.pushEventToDataLayer({ event: type + 'Accepted' })
          }
        })
      } else {
        this.pushEventToDataLayer({ event: 'AcceptCookies' })
      }
    }

    this.addConsentStateUpdateToDatalayer(acceptedTypes)
    this.updateUETConsent(acceptedTypes)
  }

  acceptAllCookies = () => {
    this.acceptCookies(this.allAdditionalTypes)
    trackMarketingEvent('Cookie Consent', 'Accept All Cookies')
  }

  rejectAllCookies = () => {
    this.acceptCookies([])
    trackMarketingEvent('Cookie Consent', 'Reject Cookies')
  }

  setEarlierAcceptedCookies = () => {
    const events = Cookies.getJSON('cookie_consent')

    // Process the legacy cookie value ("true")
    if (events === true) {
      return this.acceptCookies(this.allAdditionalTypes)
    }

    this.acceptCookies(events)
  }

  addConsentStateUpdateToDatalayer = acceptedTypes => {
    if (this.strictMode) {
      this.pushConsentToDataLayer([
        'consent',
        'update',
        this.consentModeConfig(acceptedTypes, {
          ad_storage: 'EssentialCookies',
          ad_user_data: 'EssentialCookies',
          ad_personalization: 'MarketingCookies',
          analytics_storage: 'AnalyticalCookies',
        }),
      ])
    } else {
      this.pushConsentToDataLayer([
        'consent',
        'update',
        this.consentModeConfig(acceptedTypes, {
          ad_storage: 'EssentialCookies',
          ad_user_data: 'EssentialCookies',
          ad_personalization: 'EssentialCookies',
          analytics_storage: 'EssentialCookies',
        }),
      ])
    }
  }

  updateUETConsent = acceptedTypes => {
    const uetConsentMapping = {
      ad_storage: 'MarketingCookies',
      analytics_storage: 'AnalyticalCookies',
      personalization_storage: 'MarketingCookies',
      functional_storage: 'EssentialCookies',
      security_storage: 'EssentialCookies',
    }

    const uetConsentConfig = {}
    for (const [uetKey, cookieType] of Object.entries(uetConsentMapping)) {
      uetConsentConfig[uetKey] = acceptedTypes.includes(cookieType) ? 'granted' : 'denied'
    }

    window.uetq = window.uetq || []
    window.uetq.push('consent', 'update', uetConsentConfig)
  }

  pushEventToDataLayer(data) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
  }

  pushConsentToDataLayer(data) {
    function gtag() {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(arguments)
    }
    gtag(...data)
  }

  consentModeConfig = (acceptedTypes, consentTypeToCookieMapping) => {
    function gtmConsentStateFor(cookieType) {
      const COOKIE_CONSENT_GRANTED = 'granted'
      const COOKIE_CONSENT_DENIED = 'denied'

      return acceptedTypes.indexOf(cookieType) >= 0 ? COOKIE_CONSENT_GRANTED : COOKIE_CONSENT_DENIED
    }

    const updatedConsent = { ...consentTypeToCookieMapping }

    for (const cookieType in consentTypeToCookieMapping) {
      updatedConsent[cookieType] = gtmConsentStateFor(consentTypeToCookieMapping[cookieType])
    }

    return updatedConsent
  }
}

ready(function () {
  new CookieController()
})
