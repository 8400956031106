import $ from 'jquery'
import { ready } from 'shared/ready'
import ReactDOM from 'react-dom'
import { useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const FormStates = {
  validationError: 'validationError',
  serverError: 'serverError',
  sent: 'sent',
  sending: 'sending',
}

export default function BlogAsideEmailShare({ el }) {
  const articleAbstract = $('.blog-post__hero-lead').text()
  const articleTitle = $('title').text()
  const [isOpened, setIsOpened] = useState(false)
  const [email, setEmail] = useState('')
  const [state, setState] = useState('')
  const ValidateEmail = email => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    setState(FormStates.validationError)
    return false
  }

  el.on('click', () => {
    setIsOpened(true)
  })

  $('body').on('click', function (e) {
    if (
      !$(e.target).closest('.share-email-container').length &&
      !$(e.target).closest('.blog-post-sharing__item--email').length
    ) {
      setIsOpened(false)
    }
  })

  const handleClick = () => {
    trackInternalEvent('send_blog_email', { 'page title': articleTitle })
  }

  return (
    <div>
      {isOpened && (
        <div className="share-email-container">
          <p>
            {state === FormStates.validationError && `Hmm... that email doesn't look valid`}
            {state === FormStates.sent && (
              <>
                Message sent successfully. <br />
                Please check your email.
                <img />
              </>
            )}
            {state === '' && `Send Me This!`}
          </p>
          {state !== FormStates.sent && (
            <>
              <input
                className="share-email-input"
                onChange={e => {
                  setEmail(e.target.value)
                }}
                name="email"
                css={{ marginTop: '20px', marginBottom: '12px', width: '100%' }}
              />
              <button
                className="button"
                disabled={state === 'sending'}
                css={{ borderRadius: '8px' }}
                onClick={async () => {
                  handleClick()
                  if (ValidateEmail(email)) {
                    setState(FormStates.sending)
                    const res = await fetch(`/api/app/save-page-email`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        email,
                        blog_title: articleTitle,
                        blog_description: articleAbstract,
                        blog_url: window?.location?.href,
                      }),
                    })
                    if (res.status === 200) {
                      setEmail('')
                    }
                    setState(FormStates.sent)
                  }
                }}
              >
                Submit
              </button>
            </>
          )}
        </div>
      )}
      <a
        // href="javascript:void(0)"
        onClick={() => {
          console.log('ONCLICK')
          setIsOpened(isOpened => !isOpened)
        }}
      >
        <EmailIcon />
      </a>
    </div>
  )
}

function EmailIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
      <path
        d="M4.4 1H19.6C20.645 1 21.5 1.7875 21.5 2.75V13.25C21.5 14.2125 20.645 15 19.6 15H4.4C3.355 15 2.5 14.2125 2.5 13.25V2.75C2.5 1.7875 3.355 1 4.4 1Z"
        fill="#54AFF8"
        stroke="#54AFF8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 2L12 10L1.5 2"
        stroke="#EFF2F9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

// ---
// Init
// ---

ready(function () {
  $('.blog-post-sharing__item--email').each((i, el) => {
    console.log(el)
    ReactDOM.render(<BlogAsideEmailShare el={$(el)} />, el)
  })
})
