import modals from '../../support/modals'
import { trackMarketingEvent } from '@rio/tracking'

const COOKIE_EVENT_CATEGORY = 'Accept Cookies'
const COOKIE_ACCEPT_ALL = 'Accept All Cookies'
const COOKIE_ACCEPT_ESSENTIAL = 'Accept Essential Cookies'
const COOKIE_ACCEPT_ANALYTICAL = 'Accept Analytical Cookies'
const COOKIE_ACCEPT_MARKETING = 'Accept Marketing Cookies'
const COOKIE_REJECT = 'Reject Cookies'

class CookieSettingsForm {
  constructor($form, cookieController, cookiePanel) {
    this.$form = $form
    this.cookieController = cookieController
    this.cookiePanel = cookiePanel
    this.$acceptAllCookiesButton = this.$form.find('.cookie-settings-modal__accept')
    this.$rejectAllCookiesButton = this.$form.find('.cookie-settings-modal__reject')
    this.$analyticalCheckbox = this.$form.find('#AnalyticalCookies')
    this.$marketingCheckbox = this.$form.find('#MarketingCookies')

    this.$form.on('submit', this.handleSubmit)

    this.$acceptAllCookiesButton.on('click', this.handleClickAcceptAllButton)
    this.$rejectAllCookiesButton.on('click', this.handleClickRejectButton)
  }

  handleSubmit = e => {
    e.preventDefault()
    const events = this.$form.serializeArray().map(item => item.value)

    if (this.cookieController) {
      this.cookieController.acceptCookies(events)
    }

    this.trackCookiePreferences()
    this.closeCookieControls()
  }

  handleClickAcceptAllButton = () => {
    if (this.cookieController) {
      this.cookieController.acceptAllCookies()
    }

    trackMarketingEvent(COOKIE_EVENT_CATEGORY, COOKIE_ACCEPT_ALL)
    this.closeCookieControls()
  }

  handleClickRejectButton = () => {
    if (this.cookieController) {
      this.cookieController.rejectAllCookies()
    }

    trackMarketingEvent(COOKIE_EVENT_CATEGORY, COOKIE_REJECT)
    this.closeCookieControls()
  }

  trackCookiePreferences = () => {
    trackMarketingEvent(COOKIE_EVENT_CATEGORY, COOKIE_ACCEPT_ESSENTIAL)

    if (this.$analyticalCheckbox.is(':checked')) {
      trackMarketingEvent(COOKIE_EVENT_CATEGORY, COOKIE_ACCEPT_ANALYTICAL)
    }

    if (this.$marketingCheckbox.is(':checked')) {
      trackMarketingEvent(COOKIE_EVENT_CATEGORY, COOKIE_ACCEPT_MARKETING)
    }
  }

  closeCookieControls = () => {
    if (modals.cookieSettingsModal) {
      modals.cookieSettingsModal.close()
    }

    if (this.cookiePanel) {
      this.cookiePanel.close()
    }
  }
}

export default CookieSettingsForm
