import $ from 'jquery'
import { ready } from 'shared/ready'

class DocumentsComponent {
  constructor($el) {
    if ($el.length === 0) return
    this.$el = $el

    $el.find('.documents__tab-select').on('change', this.handleChange.bind(this))
  }

  handleChange(event) {
    window.location = event.target.value
  }
}

// Init
ready(function () {
  new DocumentsComponent($('.documents'))
})
