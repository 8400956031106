import $ from 'jquery'
import { ready } from 'shared/ready'

class HomeResumeMaker {
  constructor(node) {
    this.$root = $(node)
    this.$playButton = this.$root.find('.home-resume-maker__watch')
    this.$lightbox = this.$root.find('.home-resume-maker__lightbox')
    this.$lightboxContent = this.$root.find('.home-resume-maker__lightbox-content')
    this.$closeButton = this.$root.find('.home-resume-maker__lightbox-close')

    this.iframeSrc = this.$playButton.data('iframe-src')

    this.$playButton.on('click', this.openLightbox)
    this.$lightbox.on('click', this.handleLightboxClick)
  }

  openLightbox = () => {
    // Iframe loads its assets even if it's hidden.
    // So we have to embed the iframe's markup after a user triggers the modal opening
    this.$lightboxContent.html(`
      <iframe
        width="560"
        height="315"
        src="${this.iframeSrc}"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
        allowfullscreen
      ></iframe>
    `)

    // Render the block on the page and run a transition
    this.$lightbox.addClass('home-resume-maker__lightbox--rendered')
    requestAnimationFrame(() => this.$lightbox.addClass('home-resume-maker__lightbox--visible'))
  }

  closeLightbox = () => {
    this.$lightbox.removeClass(
      'home-resume-maker__lightbox--rendered home-resume-maker__lightbox--visible',
    )

    // Video keeps playing after the iframe hiding.
    // To prevent that we remove the iframe node
    this.$lightboxContent.empty()
  }

  handleLightboxClick = event => {
    const [lightboxNode] = this.$lightbox
    const [closeButtonNode] = this.$closeButton

    if (event.target === lightboxNode || event.target === closeButtonNode) this.closeLightbox()
  }
}

ready(function () {
  $('.home-resume-maker').each(function () {
    new HomeResumeMaker(this)
  })
})
