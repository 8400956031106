import $ from 'jquery'
import { ready } from 'shared/ready'
import recaptcha from '../support/recaptcha'

class CancellationFormView {
  constructor($el) {
    if (!$el.length) return
    this.$el = $el
    this.$email = this.$el.find('.field__input')
    this.$button = this.$el.find('.button')

    this.$email.on('keyup change', this.updateFormState.bind(this))
    this.$el.on('submit', this.handleSubmit.bind(this))

    this.captchaReady = false

    // get the recaptcha score token. this token will be appended to the form
    // payload and sent to the server for verification (score from 0 to 1)
    this.fetchCaptchaScoreToken()
  }

  // Enable submit button when captcha and an email are valid
  updateFormState() {
    const isEmailNotEmpty = this.$email.val().length > 1
    const isFormValid = isEmailNotEmpty && this.captchaReady
    this.$button.prop('disabled', !isFormValid)
  }

  fetchCaptchaScoreToken() {
    recaptcha.execute({ action: 'cancellation' }).then(token => {
      // append the captcha token to the form
      this.$el.find('[name="captcha_token_v3"]').val(token)

      // mark that captcha is ready
      this.captchaReady = true
      this.updateFormState()
    })
  }

  // Disable the button after a click to prevent double submiting
  handleSubmit(event) {
    this.$button.prop('disabled', true)
  }
}

ready(function () {
  new CancellationFormView($('.cancellation__form'))
})
