import $ from 'jquery'
import { ready } from 'shared/ready'
import VisibilityObserver from '../support/VisibilityObserver'
import { Swiper, Lazy, Navigation, Scrollbar } from 'swiper'

Swiper.use([Lazy, Navigation, Scrollbar])

class AboutUsHistoryComponent {
  constructor(node) {
    this.$el = $(node)

    new VisibilityObserver(this.$el, {
      rootMargin: '50% 0% 50% 0%',
      callback: this.initializeSlider,
    })
  }

  initializeSlider = () => {
    new Swiper(this.$el.get(0), {
      slidesPerView: 'auto',
      centeredSlides: false,
      initialSlide: 0,
      effect: 'slide',
      slidesPerGroup: 1,
      grabCursor: true,
      freeMode: false,
      navigation: {
        prevEl: '.history-container-nav--prev',
        nextEl: '.history-container-nav--next',
      },
      slideClass: 'history-card__slider-item',
      wrapperClass: 'history-card__slider-inner',
      breakpoints: {
        768: {
          spaceBetween: 8,
        },
        300: {
          loop: true,
        },
      },
    })
  }
}

ready(function () {
  $('.history-card-slider__Container').each((_, node) => {
    new AboutUsHistoryComponent(node)
  })
})
