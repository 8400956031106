import $ from 'jquery'
import { ready } from 'shared/ready'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Headroom from 'headroom.js'

class Nav {
  constructor($el) {
    if ($el.length === 0) return
    this.$el = $el

    this.initDropdownMenu()
    this.initHeadroom()
    this.initMobileMenu()
  }

  initMobileMenu() {
    this.isMenuVisible = false
    this.$menu = this.$el.find('.nav__menu')
    this.$hamburger = this.$el.find('.nav__bar-hamburger')
    this.$hamburger.on('click', this.toggleMobileMenu.bind(this))
    $(window).on('resize', this.handleResize.bind(this))
  }

  handleResize() {
    if (this.isMenuVisible && window.innerWidth > 1023) this.toggleMobileMenu()
  }

  toggleMobileMenu() {
    this.isMenuVisible = !this.isMenuVisible
    this.$el.toggleClass('is-menu-opened', this.isMenuVisible)
    this.$menu.toggleClass('is-visible', this.isMenuVisible)
    this.$hamburger.toggleClass('is-menu-visible', this.isMenuVisible)

    const updateBodyScroll = this.isMenuVisible ? disableBodyScroll : enableBodyScroll
    updateBodyScroll(this.$menu.get(0))
  }

  initDropdownMenu() {
    const view = this
    view.categories = {}
    view.visibleContent = null

    view.$triggers = this.$el.find('[data-trigger]')
    view.$dropdown = this.$el.find('.nav__dropdown')
    view.$content = this.$el.find('[data-content]')

    view.$triggers.each(function (index) {
      const name = $(this).attr('data-trigger')
      const $content = view.$el.find(`[data-content=${name}]`)

      view.categories[name] = {
        trigger: $(this),
        content: $content,
      }

      if (index === 0) {
        view.$dropdown.height($content.height())
        // Fix wrong default height (related to fonts loading)
        $(window).on('load.nav', () => view.$dropdown.height($content.height()))
      }
    })

    view.$triggers.on('mouseleave', view.handleMouseLeave.bind(this))
    view.$dropdown.on('mouseleave', view.handleMouseLeave.bind(this))
    view.$triggers.on('mouseenter', view.handleMouseEnter.bind(this))
    view.$dropdown.on('mouseenter', view.handleMouseEnter.bind(this))

    view.$triggers.on('mouseleave', () => clearTimeout(view.showTimer))
    view.$triggers.on('mouseenter', function () {
      view.showTimer = setTimeout(() => view.showContent($(this).attr('data-trigger')), 100)
      $(window).off('load.nav')
    })
  }

  hideContent(name) {
    this.categories[name].trigger.removeClass('is-content-active')
    this.categories[name].content.removeClass('is-visible')
    this.visibleContent = null
  }

  showContent(name) {
    if (this.visibleContent) this.hideContent(this.visibleContent)

    this.categories[name].trigger.addClass('is-content-active')
    this.categories[name].content.addClass('is-visible')

    this.$dropdown.height(this.categories[name].content.height())
    this.toggleDropdown(true)

    this.visibleContent = name
  }

  toggleDropdown(visible) {
    this.$el.toggleClass('is-dropdown-visible', visible)
    this.$dropdown.toggleClass('is-visible', visible).toggleClass('is-hidden', !visible)

    visible ? this.headroom.freeze() : this.headroom.unfreeze()
  }

  handleMouseEnter() {
    clearTimeout(this.hideTimer)
  }

  handleMouseLeave() {
    this.hideTimer = setTimeout(() => this.toggleDropdown(false), 50)
  }

  initHeadroom() {
    const makeNavSticky = this.$el.hasClass('make--sticky')
    const offset = makeNavSticky ? null : 100

    this.headroom = new Headroom(this.$el.get(0), {
      offset,
      tolerance: 10,
      classes: {
        pinned: 'nav--pinned',
        unpinned: 'nav--unpinned',
      },
    })

    const updateState = () => {
      const shouldUseHeadroom = !makeNavSticky && window.innerWidth > 1023
      const { initialised } = this.headroom

      if (shouldUseHeadroom && !initialised) {
        this.headroom.init()
      } else if (!shouldUseHeadroom && initialised) {
        this.headroom.destroy()
      }
    }

    $(window).on('resize', updateState)
    updateState()
  }
}

ready(function () {
  new Nav($('.nav'))
})
