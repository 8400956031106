import $ from 'jquery'
import { ready } from 'shared/ready'

class AffiliateSignup {
  constructor($root) {
    this.$root = $root
    this.$swiftFields = $root.find('.js-swift-fields')
    this.$payPalField = $root.find('.js-paypal-fields')
    this.$passwordInput = $root.find('.js-password')
    this.$passwordConfirmationInput = $root.find('.js-password-confirmation')
    this.$passwordError = $root.find('.js-password-error')
    this.$passwordToggles = $root.find('.affiliates-signup__password-icon')
    this.$submitButton = $root.find('.affiliates-signup__button')

    this.paymentSystems = {
      payPal: 'paypal',
      swift: 'swift',
    }

    this.init()
  }

  init = () => {
    this.arrangeFields()
    this.initPasswordToggles()
    this.initPasswordConfirmationCheck()

    $('input[name="affiliate_application[payment_system]"]').on('change', () => {
      this.arrangeFields()
    })
  }

  checkPassword = () => {
    const password = this.$passwordInput.val()
    const passwordConfirmation = this.$passwordConfirmationInput.val()

    const fieldsAreNotEmpty = !!password && !!passwordConfirmation
    const fieldsAreNotMatch = password !== passwordConfirmation

    if (fieldsAreNotEmpty && fieldsAreNotMatch) {
      this.showPasswordError()
      this.$submitButton.attr('disabled', true)
    } else {
      this.hidePasswordError()
      this.$submitButton.attr('disabled', false)
    }
  }

  hidePasswordError = () => {
    this.$passwordError.hide()
  }

  showPasswordError = () => {
    this.$passwordError.show()
    this.$passwordInput.attr('type', 'text')
    this.$passwordConfirmationInput.attr('type', 'text')
    this.$passwordToggles.removeClass('affiliates-signup__password-icon--disabled')
  }

  initPasswordConfirmationCheck = () => {
    this.checkPassword()

    this.$passwordInput.on('change', this.checkPassword).on('focus', this.hidePasswordError)

    this.$passwordConfirmationInput
      .on('change', this.checkPassword)
      .on('focus', this.hidePasswordError)
  }

  initPasswordToggles = () => {
    this.$passwordToggles.on('click', function () {
      const $input = $(this).siblings('input')
      const type = $input.attr('type')

      if (type === 'password') {
        $input.attr('type', 'text')
        $(this).removeClass('affiliates-signup__password-icon--disabled')
      } else {
        $input.attr('type', 'password')
        $(this).addClass('affiliates-signup__password-icon--disabled')
      }
    })
  }

  arrangeFields = () => {
    const checkedSystem = this.$root
      .find('input[name="affiliate_application[payment_system]"]:checked')
      .val()

    if (checkedSystem === this.paymentSystems.payPal) {
      this.$payPalField.show()
      this.$swiftFields.hide()
    }

    if (checkedSystem === this.paymentSystems.swift) {
      this.$payPalField.hide()
      this.$swiftFields.show()
    }
  }
}

// ---
// Init
// ---
ready(function () {
  const $form = $('.affiliates-signup__form')

  if ($form.length) {
    return new AffiliateSignup($form)
  }
})
