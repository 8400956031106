import jQuery from 'jquery'

export default class VisibilityObserver {
  isSupported = 'IntersectionObserver' in window && 'IntersectionObserverEntry' in window

  constructor(el, options = {}) {
    const { callback, ...settings } = options

    // allow to pass jQuery objects
    this.el = el instanceof jQuery ? el.get(0) : el

    // display warning if callback is not providen
    if (callback) {
      this.callback = callback
    } else {
      return console.warn('VisibilityObserver: `callback` option must be providen')
    }

    if (this.isSupported) {
      // use `IntersectionObserver` if supported
      this.observer = new IntersectionObserver(this.handleIntersection, settings)
      this.observer.observe(this.el)
    } else {
      // handle callback immediately if it's not
      this.callback()
    }
  }

  isVisible = (entry = {}) => {
    return entry.isIntersecting || entry.intersectionRatio > 0
  }

  handleIntersection = entries => {
    if (!this.isVisible(entries[0])) return
    this.callback()
    this.observer.disconnect()
  }
}
