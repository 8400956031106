import $ from 'jquery'
import { ready } from 'shared/ready'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'

const $window = $(window)

export default class ScrollableSlideShow {
  constructor($el, config) {
    this.$el = $el
    this.config = config || {}
    this.$container = this.$el.find(this.config.contentSelector)
    this.$items = this.$el.find(this.config.itemSelector)

    if (!this.$el.length && !this.$items.length && !this.$container.length && !this.$items.length)
      return

    if (this.$items.length > 1) {
      $window.on('scroll', this.handleScroll)
      $window.on('resize', this.handleResize)
      this.handleResize()
    } else {
      this.$items.addClass('show').css({ position: 'static' })
    }
  }

  showStep = throttle(stepNumber => {
    if (stepNumber === this.currentStep) return false

    this.$items.eq(this.currentStep).removeClass('show')
    this.$items.eq(stepNumber).addClass('show')
    this.currentStep = stepNumber
  }, 350)

  setSizes = () => {
    this.offsetY = parseInt(this.$container.css('top')) || 0
    const trackHeight = this.$el.height()
    const trackTopPos = this.$el.offset().top
    const itemsLength = this.$items.length
    const lastItemHeight = this.$items.last().height()

    const stepHeight = (trackHeight - lastItemHeight) / itemsLength
    this.stepsBorders = this.$items.toArray().map((_, i) => {
      const nextIndex = i + 1
      const top = i === 0 ? 0 : stepHeight * i + trackTopPos
      const bottom = nextIndex < itemsLength ? stepHeight * nextIndex + trackTopPos : Infinity

      return [top, bottom]
    })

    this.$container.css({ height: lastItemHeight })
  }

  handleResize = debounce(() => {
    this.setSizes()
    this.handleScroll()
  }, 300)

  handleScroll = () => {
    const scrollTop = $window.scrollTop() + this.offsetY

    this.stepsBorders.some((step, index) => {
      const [topBorderStep, bottomBorderStep] = step

      if (scrollTop >= topBorderStep && scrollTop < bottomBorderStep) {
        this.showStep(index)
        return true
      }
      return false
    })
  }
}

// ---
// Init
// ---
ready(function () {
  return new ScrollableSlideShow($('.author__aside'), {
    contentSelector: '.author__aside-content',
    itemSelector: '.author__aside-content-item',
  })
})
