import $ from 'jquery'
import { ready } from 'shared/ready'

class BlogPostFooterComponent {
  constructor($el) {
    if (!$el.length) return
    this.$el = $el
    this.$shareButtons = $el
      .find('.blog-post-footer__share-button')
      .not('.blog-post-sharing__item--email')

    this.$shareButtons.on('click', this.handleShareClick)
  }

  handleShareClick() {
    const width = 600
    const height = 400
    const left = window.screen.width / 2 - width / 2
    const top = window.screen.height / 2 - height / 2

    window.open(
      this.href,
      '',
      `menubar=no,
      toolbar=no,
      resizable=yes,
      scrollbars=yes,
      height=${height},
      width=${width},
      left=${left},
      top=${top}`,
    )
    return false
  }
}

// ---
// Init
// ---
ready(function () {
  return new BlogPostFooterComponent($('.blog-post-footer'))
})
