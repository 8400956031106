import $ from 'jquery'

export default class ResourcesComponent {
  foldableHeight = 187
  classes = {
    folded: 'resources--folded',
    dynamic: 'resources--dynamic',
    toggle: 'resources__toggle',
    content: 'resources__content',
    toggleVisible: 'resources__toggle--visible',
  }

  constructor(resourcesNode) {
    this.resourcesNode = resourcesNode

    window.addEventListener('load', this.initialize)
  }

  initialize = () => {
    const { resourcesNode } = this
    const contentNode = resourcesNode.querySelector(`.${this.classes.content}`)
    const height = contentNode.clientHeight

    if (height < this.foldableHeight) return

    $(contentNode).css({ height })

    $(resourcesNode)
      .addClass(this.classes.dynamic)
      .addClass(this.classes.folded)
      .find(`.${this.classes.toggle}`)
      .addClass(this.classes.toggleVisible)
      .on('click', () => {
        $(resourcesNode).toggleClass(this.classes.folded)
      })
  }
}
