import VisibilityObserver from '../support/VisibilityObserver'
class Faq {
  constructor({ root, classes }) {
    if (!root.length) return

    this.opened = false
    this.duration = 200

    this.root = root
    this.classes = classes

    this.$questions = this.root.find(`.${this.classes.question}`)
    this.currentClass = this.classes.opened

    this.$questions.each(index => {
      this.$questions.eq(index).on('click', () => this.toggle(index))
    })

    if (this.classes.seen) {
      new VisibilityObserver(this.root, {
        rootMargin: '50% 0% 50% 0%',
        callback: () => this.root.addClass(this.classes.seen),
      })
    }
  }

  toggle(index) {
    if (this.opened === index) {
      this.hide(index)
    } else if (this.opened !== false) {
      this.hide(this.opened)
      this.show(index)
    } else {
      this.show(index)
    }
  }

  show(index) {
    const $question = this.$questions.eq(index).addClass(this.currentClass)
    const $text = $question.find(`.${this.classes.content}`)
    $text
      .slideDown(this.duration)
      .animate({ opacity: 1 }, { duration: this.duration, queue: false })
    this.opened = index
  }

  hide(index) {
    const $question = this.$questions.eq(index).removeClass(this.currentClass)
    const $text = $question.find(`.${this.classes.content}`)
    $text.slideUp(this.duration).animate({ opacity: 0 }, { duration: this.duration, queue: false })
    this.opened = false
  }
}

export default Faq
