import $ from 'jquery'
import { ready } from 'shared/ready'
import { PageLoader } from './pageLoader'
import debounce from '../utils/debounce'

const FEED_MODES = {
  feed: 'FEED',
  searchResults: 'SEARCH',
}

const searchPanels = []

/**
 * Implements search in resume.io/blog
 */
class BlogSearch {
  $allSearchPanels = $([])
  lastSearchQuery = ''
  initialPostsFeedMode = FEED_MODES.feed
  classes = {
    navigation: {
      container: 'blog-main__top-box-nav',
      openSearch: 'blog-main__top-box-nav--show-search',
    },
    spinner: {
      container: 'blog-main__search-spinner',
      visible: 'blog-main__search-spinner--visible',
    },
    searchResultContainer: 'blog-main__search-result-posts',
    blogMainContainer: {
      container: 'blog-main__container',
      showSearchResults: 'blog-main__container--show-search-results',
    },
    blogPostsContainer: 'blog-main__posts-feed',
    blogMainPostContainer: 'blog-main__main-post',
    counter: 'blog-main__search-result-counter',
    open: 'blog-search--open',
    responsive: 'blog-search--responsive',
    clearButton: 'blog-search__clear',
    openResponsiveSearchButton: 'blog-search__open-button',
  }

  constructor($el, pageLoader) {
    // Init form elements
    this.$form = $el
    this.$input = $el.find('input')
    this.$clear = $el.find(`.${this.classes.clearButton}`)
    this.$navigation = $(`.${this.classes.navigation.container}`)
    this.$searchResultContainer = $(`.${this.classes.searchResultContainer}`)
    this.$blogMainPostContainer = $(`.${this.classes.blogMainPostContainer}`)
    this.$blogPostsContainer = $(`.${this.classes.blogPostsContainer}`)
    this.$counter = $(`.${this.classes.counter}`)
    this.$spinner = $(`.${this.classes.spinner.container}`)
    this.$blogMainContainer = $(`.${this.classes.blogMainContainer.container}`)
    this.$openResponsiveSearchButton = $(`.${this.classes.openResponsiveSearchButton}`)

    this.pageLoader = pageLoader

    this.pageLoader && this.pageLoader.setQueryParams({ search: this.$input.val() })

    // Check if page wes rendered with search query parameter
    // Used in setDefaultState method to load all posts after search clear
    this.initialPostsFeedMode = this.isEmptyQuery() ? FEED_MODES.feed : FEED_MODES.searchResults

    if (!this.isEmptyQuery()) {
      this.pageLoader && this.pageLoader.startObserveLoader()
      this.pageLoader && this.pageLoader.show()
    }

    this.setEventListeners()

    searchPanels.push(this)
  }

  setEventListeners = () => {
    this.$form.on('click', this.open)
    this.$input.on('change keyup', this.onInputChange)
    this.$input.on('focus', this.open)
    this.$clear.on('click', this.onClear)
    if (this.$form.hasClass(this.classes.responsive)) {
      this.$openResponsiveSearchButton.on('click', this.open)
    }

    // Prevent native form submit
    this.$form.on('submit', e => e.preventDefault())
  }

  // Fetch HTML with rendered posts filtered by form data
  search = () => {
    const action = this.$form.attr('action')
    const data = this.$form.serializeArray()

    this.showSpinner()
    this.pageLoader && this.pageLoader.hide()

    $.post(action, data).done(this.showResults).fail(this.setDefaultState)
  }

  // Shows rendered blog posts and posts counter
  showResults = response => {
    if (response.searchQuery === this.$input.val()) {
      this.hideSpinner()

      if (!this.isEmptyQuery()) {
        $(window).scrollTop(0)
        this.setSearchContent(response.html)
        this.getAllSearchPanels().val(response.searchQuery)
        this.getAllSearchPanels().filter(':visible').focus()
        this.showResultsCounter(response.localizedPostsCount)
        if (response.postsCount) {
          this.pageLoader && this.pageLoader.reset()
        }
      } else {
        this.showEnvironmentBlocks()
        this.setBlogContent(response.mainPostHtml, response.html)
        this.initialPostsFeedMode = FEED_MODES.feed
      }
    }
  }

  // Start search by input value with 500ms debounce
  onInputChange = debounce(() => {
    if (this.isEmptyQuery()) {
      this.setDefaultState()
    } else if (this.lastSearchQuery !== this.$input.val()) {
      searchPanels.forEach(searchPanel => (searchPanel.lastSearchQuery = this.$input.val()))
      this.pageLoader && this.pageLoader.setQueryParams({ search: this.lastSearchQuery })
      this.$searchResultContainer.html('')
      this.search()
    }
  }, 500)

  // Returns blog state to the one before search
  setDefaultState = () => {
    searchPanels.forEach(searchPanel => (searchPanel.lastSearchQuery = ''))
    this.getAllSearchPanels().val(null)
    this.$searchResultContainer.html('')
    this.pageLoader && this.pageLoader.setQueryParams({ search: this.lastSearchQuery })

    if (this.initialPostsFeedMode === FEED_MODES.feed) {
      this.showEnvironmentBlocks()
    } else {
      this.search()
    }

    this.hideResultsCounter()
  }

  // Injects HTML into posts container and starts images lazy-loading
  setSearchContent = html => {
    this.$searchResultContainer.html(html)
  }

  setBlogContent = (mainPostHtml, postsHtml) => {
    this.$searchResultContainer.html('')
    this.$blogMainPostContainer.html(mainPostHtml)
    this.$blogPostsContainer.html(postsHtml)
  }

  // Clear button click event handler
  // Clears input and closes form
  onClear = event => {
    event.stopPropagation()
    this.setDefaultState()
    this.close()
  }

  // Open form, make search input visible
  open = () => {
    if (!this.isOpen()) {
      searchPanels.forEach(searchBox => searchBox.$form.addClass(searchBox.classes.open))
      this.$navigation.addClass(this.classes.navigation.openSearch)
      this.$input.focus()
    }
  }

  // Close form, make search input hidden
  close = () => {
    searchPanels.forEach(searchBox => searchBox.$form.removeClass(searchBox.classes.open))
    this.$navigation.removeClass(this.classes.navigation.openSearch)
  }

  // Switch related navigation DOM node's class to show search results
  showResultsCounter = counterText => {
    this.$counter.text(counterText)
  }

  // Switch navigation back to normal
  hideResultsCounter = () => {
    this.$counter.text('')
  }

  hideEnvironmentBlocks = () => {
    this.$blogMainContainer.addClass(this.classes.blogMainContainer.showSearchResults)
  }

  showEnvironmentBlocks = () => {
    this.$blogMainContainer.removeClass(this.classes.blogMainContainer.showSearchResults)
  }

  // Shows loading spinners while posts fetching
  showSpinner = () => {
    this.$spinner.addClass(this.classes.spinner.visible)
    this.hideEnvironmentBlocks()
  }

  // Hides spinner when posts are fetched
  hideSpinner = () => this.$spinner.removeClass(this.classes.spinner.visible)

  // Check if search input has non-empty string
  isEmptyQuery = () => !this.$input.val().trim()

  // Check if search form is open
  isOpen = () => this.$form.hasClass(this.classes.open)

  getAllSearchPanels = () => {
    const inputs = searchPanels.map(searchPanel => searchPanel.$input[0])
    return $(inputs)
  }
}

// ---
// Init
// ---
ready(function () {
  const $loader = $('.blog-main__search-result-loader')
  let pageLoader

  // Usually 1 pageLoader on a page if exists
  if ($loader.length) {
    pageLoader = new PageLoader($loader, {
      targetPostsSelector: '.blog-main__search-result-posts',
    })

    pageLoader.hide()
  }

  // Usually 3 blog-search panels on a page if exists
  $('.blog-search').each(function () {
    new BlogSearch($(this), pageLoader)
  })
})
