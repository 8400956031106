import $ from 'jquery'
import { ready } from 'shared/ready'
import VisibilityObserver from '../support/VisibilityObserver'
import { trackInternalEvent } from '@rio/tracking'

class BlogPostComments {
  constructor($blogPostComments, config) {
    if (!$blogPostComments.length) return

    this.$blogPostComments = $blogPostComments
    this.trackingLabel = config.trackingLabel

    new VisibilityObserver(this.$blogPostComments, {
      rootMargin: '50% 0% 50% 0%',
      callback: () => this.loadScript('https://cdn.commento.io/js/commento.js'),
    })

    new VisibilityObserver(this.$blogPostComments, {
      rootMargin: '0%',
      callback: () => trackInternalEvent('see_comments_section', { label: this.trackingLabel }),
    })
  }

  loadScript = url => {
    const head = document.querySelector('head')
    const script = document.createElement('script')

    this.$blogPostComments.addClass('loading')

    script.type = 'text/javascript'
    script.src = url
    head.appendChild(script)

    script.onload = () => {
      this.$blogPostComments.removeClass('loading')
    }
  }
}

// ---
// Init
// ---
ready(function () {
  return new BlogPostComments($('.blog-post-comments'), { trackingLabel: 'posts' })
})
