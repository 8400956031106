import VisibilityObserver from '../../support/VisibilityObserver'
import matchMediaQueries from '../../support/mediaQueries'

export default class CompareResumesComponent {
  constructor($container) {
    this.introAnimationDuration = 900 // in ms
    this.classes = {
      goodResume: '.compare-resumes__resume--good',
      view: '.compare-resumes__view',
      intro: 'compare-resumes__view--intro',
      ready: 'compare-resumes__view--ready',
      hover: 'compare-resumes__view--hover',
    }

    this.$goodResume = $container.find(this.classes.goodResume)
    this.$view = $container.find(this.classes.view)

    this.$goodResume.on('mouseover', this.handleResumeHover)
    this.$goodResume.on('mouseleave', () => {
      this.$view.removeClass(this.classes.hover)
    })

    new VisibilityObserver($container, {
      rootMargin: '-50% 0% -50% 0%',
      callback: this.animate,
    })
  }

  handleResumeHover = () => {
    if (this.$view.hasClass(this.classes.ready)) {
      this.$view.addClass(this.classes.hover)
    }
  }

  animate = () => {
    this.$view.addClass(this.classes.intro)

    setTimeout(() => {
      this.$view.addClass(this.classes.ready)

      if (matchMediaQueries().isPhone) {
        setTimeout(this.handleResumeHover, 500)
      }
    }, this.introAnimationDuration)
  }
}
