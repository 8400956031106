import $ from 'jquery'
import { ready } from 'shared/ready'
import VisibilityObserver from '../support/VisibilityObserver'
import { Swiper, Lazy, Navigation, Scrollbar } from 'swiper'

Swiper.use([Lazy, Navigation, Scrollbar])

class AboutUsLeadershipComponent {
  constructor(node) {
    this.$el = $(node)
    new VisibilityObserver(this.$el, {
      rootMargin: '50% 0% 50% 0%',
      callback: this.initializeSlider,
    })
  }

  initializeSlider = () => {
    new Swiper(this.$el.get(0), {
      slidesPerView: 1,
      loop: true,
      centeredSlides: true,
      navigation: {
        prevEl: '.leadership-container-nav--prev',
        nextEl: '.leadership-container-nav--next',
      },
      slideClass: 'leadership-main-container__slider-item',
      wrapperClass: 'leadership-main-container__slider',
      pagination: {
        el: '.leadership__pagination',
        clickable: true,
      },
    })
  }
}

ready(function () {
  $('.leadership-main-container').each((_, node) => {
    new AboutUsLeadershipComponent(node)
  })
})
