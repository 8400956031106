import { ready } from 'shared/ready'
import $ from 'jquery'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'

class ExamplesSeniorityComponent {
  constructor($el) {
    if (!$el) return

    this.$el = $el
    this.$slider = this.$el.find('.example-examples-slider').first()

    this.initSlider()

    let titles = $el.find('.senioriy-examples__title')
    let activeTitle = $el.find('.senioriy-examples__title.active').first()

    let global = this
    titles.each(function (index, element) {
      $(element).on('click', function () {
        const dataIndex = $(element).data('slick')
        activeTitle && $(activeTitle).removeClass('active')

        $(element).addClass('active')
        activeTitle = element

        global.goToSlide(dataIndex)
      })
    })
  }

  goToSlide = index => {
    this.$slider.slick('slickGoTo', index)
  }

  getCurrentSlickOptions = () => {
    return {
      dots: false,
      infinite: false,
      speed: 600,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: 10,
      initialSlide: 0,
      prevArrow: null,
      nextArrow: null,
      variableWidth: true,
    }
  }

  initSlider = () => {
    this.$slider.slick(this.getCurrentSlickOptions())
  }
}

ready(function () {
  new ExamplesSeniorityComponent($('.seniority-examples__container'))
})
