function matchMediaQueries(width = window.innerWidth) {
  return {
    isPhone: width <= 767,
    isTablet: width <= 1023,
    isLaptop: width <= 1199,
    isDesktop: width >= 1200,
  }
}

export default matchMediaQueries
