import $ from 'jquery'

export default class SalariesComponent {
  constructor($container) {
    this.classes = {
      salaryItem: '.salary__item',
      salaryItemAmount: '.salary__item_amnt',
      salaryItemBar: '.salary__item_bar',
    }

    const salaryItems = $container.find(this.classes.salaryItem)
    let total = this.getTotal(salaryItems)

    salaryItems.each((i, v) => {
      const value = parseFloat(
        $(v).find(this.classes.salaryItemAmount).text().replace('$', '').replace(' / yr'),
      )
      const width = this.getWidth(value, total, salaryItems.length)
      $(v)
        .find(this.classes.salaryItemBar)
        .width(width.toString() + '%')
    })
  }

  getTotal(salaryItems) {
    let total = 0
    salaryItems.each((i, v) => {
      total += parseFloat($(v).find('.salary__item_amnt').text().replace('$', '').replace(' / yr'))
    })
    return total
  }

  getWidth(value, total, count) {
    if (count === 1) {
      return 100
    }
    return Math.round((value / total) * 100) + count * 15
  }
}
