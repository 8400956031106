import $ from 'jquery'
import { ready } from 'shared/ready'
import throttle from '../utils/throttle'
import { trackInternalEvent } from '@rio/tracking'
import matchMediaQueries from '../support/mediaQueries'
import { Collapse } from './collapse'

class ExamplesRootComponent {
  constructor($el) {
    if ($el.length === 0) return

    this.toggleShowMoreCategories()
    this.exampleType = $el.data('example-type')

    if ($el.data('design') === 'new') {
      // Init collapse for a/b test
      new Collapse($el, {
        itemClass: 'examples-category-card__collapse',
        bodyClass: 'examples-category-card__collapse-body',
        toggleClass: 'examples-category-card__collapse-button',
        onOpen: () =>
          trackInternalEvent(`click_${this.exampleType}_examples_category`, {
            source: 'example_section_show_all',
          }),
      })

      return
    }

    this.$el = $el
    this.isActive = false
    this.$stickyArea = $el.find('.examples-root__sidebar-sticky-area')
    this.$scrollTop = $el.find('.examples-root__scroll-top')

    // Do not execute the callback every single time the user scrolls
    this.throttledScrollHandler = throttle(this.handleScroll.bind(this), 150)

    window.addEventListener('resize', this.handleResize.bind(this))
    this.handleResize()

    this.$scrollTop.on('click', this.scrollToCategories.bind(this))
  }

  // Do not handle scroll if the sidebar isn't visible
  handleResize() {
    const isSidebarVisible = matchMediaQueries().isDesktop

    if (isSidebarVisible && !this.isActive) {
      window.addEventListener('scroll', this.throttledScrollHandler, { passive: true })
      this.handleScroll()
      this.isActive = true
    } else if (!isSidebarVisible && this.isActive) {
      window.removeEventListener('scroll', this.throttledScrollHandler)
      this.isActive = false
    }
  }

  handleScroll() {
    const isButtonVisible = $(window).scrollTop() > this.$stickyArea.offset().top
    this.$scrollTop.toggleClass('examples-root__scroll-top--visible', isButtonVisible)
  }

  scrollToCategories() {
    const navbarHeight = $('.nav').height()
    const scrollTop = this.$el.offset().top - navbarHeight
    $('html, body').animate({ scrollTop }, 300)
  }

  toggleShowMoreCategories() {
    const section = document.getElementById('example-categories-section')

    if (!section) return

    const showMoreButton = section.getElementsByClassName('examples-categories-button')[0]

    if (!showMoreButton) return

    showMoreButton.addEventListener('click', event => {
      let categories = section.getElementsByClassName('example-category-entry')

      for (let category of categories) {
        category.classList.toggle('show-category')
      }

      showMoreButton.classList.toggle('is-open')
      const spanElement = showMoreButton.firstElementChild
      if (!spanElement) return
      const currentText = spanElement.textContent

      const newText = showMoreButton.getAttribute('data-alt-text')

      spanElement.textContent = newText
      // when moving this to ts we can just use currentText!
      showMoreButton.setAttribute('data-alt-text', currentText || '')
    })
  }
}

ready(function () {
  new ExamplesRootComponent($('.examples-root'))
})
