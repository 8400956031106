import $ from 'jquery'
import { ready } from 'shared/ready'
import matchMediaQueries from '../support/mediaQueries'

class VideoCtaComponent {
  constructor($el) {
    // Do not insert the video on mobile devices or legacy browsers
    if ($el.length === 0 || !this.isDeviceSupported()) return

    this.$el = $el
    this.$visual = $el.find('.video-cta__visual')
    this.videoSrc = $el.data('video-src')
    this.video = this.createVideoNode()
    this.wasPlayed = false

    // Observer to load video before it comes into viewport
    this.loadObserver = new IntersectionObserver(this.handleLoadIntersection.bind(this), {
      rootMargin: '75% 0% 75% 0%',
    })
    this.loadObserver.observe(this.video)

    // Observer to play/replay video as it comes into viewport
    this.playObserver = new IntersectionObserver(this.handlePlayIntersection.bind(this))
    this.playObserver.observe(this.video)
  }

  isDeviceSupported() {
    const hasObserver = 'IntersectionObserver' in window && 'IntersectionObserverEntry' in window
    const isMobile = matchMediaQueries().isTablet
    return !isMobile && hasObserver
  }

  createVideoNode() {
    return $(`<video class="video-cta__video" muted="muted" preload="none" />`)
      .attr({
        src: this.videoSrc,
        type: 'video/mp4',
      })
      .appendTo(this.$visual)
      .get(0)
  }

  handlePlayIntersection(entries) {
    if (!this.isVisible(entries[0])) return

    this.video.currentTime = 0
    this.video.play()
    this.wasPlayed = true
  }

  handleLoadIntersection(entries) {
    if (!this.isVisible(entries[0])) return

    // If `video.play()` was called first,
    // `video.load()` will interrupt playing.
    // `this.wasPlayed` flag helps to avoid that
    if (!this.wasPlayed) this.video.load()
    this.loadObserver.disconnect()
  }

  isVisible(entry = {}) {
    return entry.isIntersecting || entry.intersectionRatio > 0
  }
}

ready(function () {
  new VideoCtaComponent($('.video-cta'))
})
