import $ from 'jquery'
import Clipboard from 'clipboard'

export default class EmbedComponent {
  constructor($el) {
    if (!Clipboard.isSupported()) return

    const $button = $el.find('.embed-code__button')
    const $buttonText = $el.find('.embed-code__button-text')

    const clipboard = new Clipboard($button.get(0), {
      target: trigger => {
        return $(trigger).closest('.embed-code').find('.embed-code__content').get(0)
      },
    })

    clipboard.on('success', () => {
      $buttonText.text($buttonText.data('copied-text'))
      setTimeout(() => $buttonText.text($buttonText.data('default-text')), 1000)
    })
  }
}
