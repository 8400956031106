/*
 * Loads the Google ReCAPTCHA scripts.
 * Abstracts the dirty dynamic script logic into a Promise-based interface.
 */
class Recaptcha {
  constructor(siteKey) {
    this.api = null
    this.siteKey = siteKey
  }

  getApi() {
    // Do not load the script twice
    if (this.api) return Promise.resolve(this.api)

    return new Promise(resolve => {
      const cbName = 'grecaptchaLoaded'

      window[cbName] = () => {
        this.api = window.grecaptcha
        this.api.ready(() => resolve(this.api))
      }

      const script = document.createElement('script')
      script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}&onload=${cbName}`
      document.head.appendChild(script)
    })
  }

  execute(options) {
    return this.getApi().then(api => {
      return api.execute(this.siteKey, options)
    })
  }
}

export default new Recaptcha(process.env.GRECAPTCHA_SITE_KEY)
