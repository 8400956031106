import $ from 'jquery'

// ---
// Base class for all modal windows
// ---
class BaseModal {
  // Constructor
  constructor($root, options = {}) {
    if (!$root || !$root.length) return

    this.$root = $root
    this.options = options
    this.preventFromClosing = false

    $root.on('click', '.modal__overlay', this.handleClickOverlay.bind(this))
    $root.on('click', '.modal__close', this.close.bind(this))
  }

  close(forceClose = false) {
    if (forceClose || !this.preventFromClosing) {
      this.$root.removeClass('is-visible is-animated')
      $('body').removeClass('is-modal-open')
    }
  }

  open() {
    this.$root.addClass('is-visible')
    requestAnimationFrame(() => {
      this.$root.addClass('is-animated')
    })
    $('body').addClass('is-modal-open')
  }

  togglePreventClosing(state = true) {
    this.preventFromClosing = state
  }

  // Close the modal dialog when the user
  // clicked on an overlay
  // Works only if options.isBlocking isn't set
  handleClickOverlay(event) {
    const isOverlay =
      $(event.target).hasClass('modal-overlay') || $(event.target).hasClass('modal__overlay')

    if (isOverlay && !this.options.isBlocking) {
      this.close()
    }
  }
}

export default BaseModal
