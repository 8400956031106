import $ from 'jquery'
import { ready } from 'shared/ready'
import recaptcha from '../support/recaptcha'

class ContactUsView {
  constructor($el) {
    if (!$el.length) return
    this.$el = $el

    this.initForm()
    this.initTopics()
  }

  initForm() {
    this.$form = this.$el.find('.contact__form')
    this.$button = this.$el.find('.button')
    this.isSubmitting = false
    this.fetchCaptchaScoreToken()

    this.$form.on('submit', this.handleFormSubmit.bind(this))
  }

  fetchCaptchaScoreToken() {
    recaptcha.execute({ action: 'contact_form' }).then(token => {
      this.$el.find('[name="captcha_token_v3"]').val(token)
      this.$button.prop('disabled', false)
    })
  }

  toggleSubmitting(state) {
    this.isSubmitting = state
    this.$button.prop('disabled', state)
  }

  handleFormSubmit(event) {
    event.preventDefault()

    if (this.isSubmitting) return
    this.toggleSubmitting(true)

    const formValues = this.$form.serializeArray()
    this.submitTicket(formValues)
  }

  submitTicket(formValues) {
    const subjectKey = formValues.find(el => el.name === 'topic')
    if (subjectKey) formValues.push({ name: 'subject', value: subjectKey.value })

    $.post('/support/ticket', formValues).done(response => {
      this.toggleSubmitting(false)
      if (!response.success) return this.handleError(response)
      this.handleSuccess(response)
    })
  }

  handleSuccess() {
    this.$el.find('.contact__form-success').addClass('is-visible')
    this.$el.find('.contact__form-container').addClass('is-hidden')
  }

  handleError(response) {
    this.showErrorMessage(response.error)
    if (response.error_code === 'captcha_required') this.showCaptcha()
  }

  showErrorMessage(message) {
    const $error = this.$el.find('.contact__form-error')
    $error.text(message).show()
  }

  initTopics() {
    this.$topics = this.$form.find('input[name="topic"]')
    this.topic = null

    this.$topics.on('change', this.handleTopicChange.bind(this))
    this.handleTopicChange()
  }

  handleTopicChange() {
    const topic = this.$topics.filter(':checked').val()
    if (this.topic) this.$form.removeClass(`contact__form--${this.topic}`)
    this.$form.addClass(`contact__form--${topic}`)
    this.topic = topic
  }
}

ready(function () {
  new ContactUsView($('.contact'))
})
