import $ from 'jquery'
import { ready } from 'shared/ready'
import VisibilityObserver from '../support/VisibilityObserver'
import { Swiper, Lazy, Navigation, Scrollbar } from 'swiper'

Swiper.use([Lazy, Navigation, Scrollbar])

class ExamplesCardsSlider {
  constructor(node) {
    this.$el = $(node)

    new VisibilityObserver(this.$el, {
      rootMargin: '50% 0% 50% 0%',
      callback: this.initializeSlider,
    })
  }

  initializeSlider = () => {
    new Swiper(this.$el.get(0), {
      loop: this.$el.find('.examples-category-card__slider-item').length > 3,
      slidesPerView: 'auto',
      threshold: 15,
      navigation: {
        prevEl: '.examples-category-card__nav--prev',
        nextEl: '.examples-category-card__nav--next',
      },
      pagination: {
        el: '.examples-category-card__slider-pagination',
        clickable: true,
      },
      lazy: {
        checkInView: true,
        loadPrevNext: true,
        loadOnTransitionStart: true,
        elementClass: 'examples-category-card__link-image',
      },
      slideClass: 'examples-category-card__slider-item',
      wrapperClass: 'examples-category-card__slider-inner',
      breakpoints: {
        1200: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 8,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 8,
        },
      },
    })
  }
}

ready(function () {
  $('.examples-category-card__slider-container').each((_, node) => {
    new ExamplesCardsSlider(node)
  })
})
