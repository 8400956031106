import $ from 'jquery'
import { ready } from 'shared/ready'
import parse from 'srcset-parse'

const DRP = window.devicePixelRatio || 1

const getBestSourceUrl = srcset => {
  try {
    const sources = parse(srcset).sort((a, b) => a.density - b.density)
    const fallback = sources.shift()

    return sources.reduce((value, source) => {
      return source.density && source.density <= DRP ? source.url : value
    }, fallback.url)
  } catch (error) {
    return srcset
  }
}

class LazyLoader {
  constructor() {
    if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window) {
      this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
        rootMargin: '25% 0% 25% 0%',
      })
    }
  }

  attach(node) {
    $(node)
      .find('[data-lazy-bg]')
      .each((i, element) => this.add(element))
  }

  add(element) {
    if (this.observer) {
      this.observer.observe(element)
    } else {
      this.load(element)
    }
  }

  handleIntersection(entries, observer) {
    entries
      .filter(entry => entry.isIntersecting || entry.intersectionRatio > 0)
      .forEach(entry => {
        this.load(entry.target)
        observer.unobserve(entry.target)
      })
  }

  load(element) {
    const img = new Image()
    const $targetElement = $(element)
    const src = getBestSourceUrl($targetElement.attr('data-lazy-bg'))

    img.onload = () => {
      if ($targetElement.is('img')) {
        element.src = src
      } else {
        element.style.backgroundImage = `url(${src})`
      }

      element.classList.add('is-image-loaded')
    }
    img.src = src
  }
}

const lazyLoader = new LazyLoader()

ready(function () {
  lazyLoader.attach(document.body)
})

export default lazyLoader
