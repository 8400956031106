import $ from 'jquery'
import { ready } from 'shared/ready'
import { Swiper, Lazy, Navigation, EffectCoverflow } from 'swiper'
import VisibilityObserver from '../support/VisibilityObserver'
import matchMediaQueries from '../support/mediaQueries'

Swiper.use([Lazy, Navigation, EffectCoverflow])

class HomeTemplates {
  constructor(node) {
    this.$el = $(node)

    new VisibilityObserver(this.$el, {
      rootMargin: '50% 0% 50% 0%',
      callback: this.initializeSlider,
    })
  }

  initializeSlider = () => {
    const { isPhone } = matchMediaQueries()

    this.$gallery = this.$el.find('.home-templates__gallery')
    this.$slider = this.$el.find('.home-templates__slider')
    this.$itemLinks = this.$el.find('.home-templates__item-link')
    this.$headers = this.$el.find('.home-templates__header')
    this.$useLink = this.$el.find('.home-templates__use-link')
    this.$useLink.toggleClass('button--small', isPhone)

    this.swiper = new Swiper(this.$gallery.get(0), {
      loop: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      threshold: 15,
      grabCursor: true,
      slideClass: 'home-templates__item',
      slideActiveClass: 'home-templates__item--active',
      wrapperClass: 'home-templates__slider',
      lazy: {
        checkInView: true,
        loadPrevNext: true,
        loadPrevNextAmount: 3,
        loadOnTransitionStart: true,
        elementClass: 'home-templates__item-image',
      },
      navigation: {
        prevEl: '.home-templates__nav--prev',
        nextEl: '.home-templates__nav--next',
      },
      on: {
        slideChange: swiper => {
          this.$headers.removeClass('home-templates__header--active')
          this.$headers.eq(swiper.realIndex).addClass('home-templates__header--active')
          this.$useLink.attr('href', this.$itemLinks.eq(swiper.realIndex).attr('href'))
        },
      },
      spaceBetween: isPhone ? 16 : 0,
      effect: isPhone ? 'slide' : 'coverflow',
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 250,
        modifier: 1,
        slideShadows: false,
      },
    })

    this.$el.addClass('home-templates--ready')

    // Fix incorrect slide dimensions
    $(window).on('load', () => this.swiper.update())
  }
}

ready(function () {
  $('.home-templates').each((i, node) => {
    new HomeTemplates(node)
  })
})
