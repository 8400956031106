import $ from 'jquery'
import { ready } from 'shared/ready'
import VisibilityObserver from '../support/VisibilityObserver'
import { Swiper, Lazy, Navigation, Scrollbar } from 'swiper'

Swiper.use([Lazy, Navigation, Scrollbar])

class BlogFeaturedPostComponent {
  constructor(node) {
    this.$el = $(node)

    new VisibilityObserver(this.$el, {
      rootMargin: '50% 0% 50% 0%',
      callback: this.initializeSlider,
    })
  }

  initializeSlider = () => {
    new Swiper(this.$el.get(0), {
      loop: this.$el.find('.blog-new-featured-posts__slider-item').length > 3,
      slidesPerView: 1,
      threshold: 15,
      centeredSlides: true,
      spaceBetween: 8,
      navigation: {
        prevEl: '.blog-featured-posts__nav--prev',
        nextEl: '.blog-featured-posts__nav--next',
      },
      slideClass: 'blog-new-featured-posts__slider-item',
      wrapperClass: 'blog-new-featured-posts__slider-inner',
      breakpoints: {
        1024: {
          spaceBetween: 0,
        },
      },
    })
  }
}

ready(function () {
  $('.blog-new-featured-posts__slider-container').each((_, node) => {
    new BlogFeaturedPostComponent(node)
  })
})
