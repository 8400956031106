import modals from '../../support/modals'

class CookiePanel {
  constructor($panel, cookieController) {
    this.$panel = $panel
    this.cookieController = cookieController
    this.$acceptButton = this.$panel.find('.cookie-panel__accept')
    this.$rejectButton = this.$panel.find('.cookie-panel__reject')
    this.$settingsButton = this.$panel.find('.cookie-panel__settings')

    this.open()
    this.$acceptButton.click(this.handleAcceptAllCookies)
    this.$rejectButton.click(this.handleRejectAllCookies)
    this.$settingsButton.on('click', () => modals.cookieSettingsModal.open())
  }

  handleAcceptAllCookies = () => {
    if (this.cookieController) {
      this.cookieController.acceptAllCookies()
    }

    this.close()
  }

  handleRejectAllCookies = () => {
    if (this.cookieController) {
      this.cookieController.rejectAllCookies()
    }

    this.close()
  }

  open = () => {
    this.$panel.addClass('is-open')
  }

  close = () => {
    this.$panel.removeClass('is-open')
  }
}

export default CookiePanel
