class Cookiebar {
  constructor($el, cookieController) {
    this.$el = $el
    this.cookieController = cookieController
    this.$acceptButton = this.$el.find('.cookiebar__button')

    this.$el.addClass('is-open')

    const links = document.links

    Array.from(links).forEach(link => link.addEventListener('click', this.handleAcceptAllCookies))
    this.$acceptButton.click(this.handleAcceptAllCookies)

    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    this.handleAcceptAllCookies()
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleAcceptAllCookies = () => {
    if (this.cookieController) {
      this.cookieController.acceptAllCookies()
    }

    this.$el.removeClass('is-open')
  }
}

export default Cookiebar
