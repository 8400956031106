class LanguageSelect {
  constructor($root) {
    this.select = $root.find('select')

    this.init()
  }

  init() {
    this.select.on('change select', e => {
      window.location.href = e.currentTarget.value
    })
  }
}

export default LanguageSelect
