import $ from 'jquery'
import { ready } from 'shared/ready'

/**
 * Implements loading new pages by scroll to bottom
 */
export class PageLoader {
  page = 1

  constructor($el, params) {
    // Init form elements
    this.$loader = $el

    if (!params || !this.$loader.length) return
    this.url = this.$loader.data('api-endpoint')
    this.$targetPosts = $(params.targetPostsSelector)
    this.queryParams = params.queryParams

    this.observer = new IntersectionObserver(this.handleIntersection, { threshold: [0.7] })
  }

  startObserveLoader = () => this.observer.observe(this.$loader[0])
  stopObserveLoader = () => this.observer.disconnect()

  show = () => this.$loader.css({ display: 'flex' })
  hide = () => this.$loader.hide()

  handleIntersection = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.stopObserveLoader()
        this.loadPage()
      }
    })
  }

  loadPage = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const query = {
      page: ++this.page,
      per_page: urlParams.get('per_page'),
      ...this.queryParams,
    }

    const params = new URLSearchParams()
    for (const key in query) if (query[key]) params.append(key, query[key])
    const queryString = params.toString()

    $.get(`${this.url}?${queryString}`)
      .done(response => {
        const isPageLimit = response.size * response.page >= response.totalElements
        if (
          response.numberOfElements === 0 ||
          response.numberOfElements < response.size ||
          isPageLimit
        ) {
          this.hide()
        }

        this.$targetPosts.append(response.content)

        this.startObserveLoader()
      })
      .fail()
      .always()
  }

  setQueryParams = query => {
    this.queryParams = {
      ...this.queryParams,
      ...query,
    }
  }

  reset = () => {
    this.page = 1
    this.startObserveLoader()
    this.show()
  }
}

// ---
// Init
// ---
ready(function () {
  $('.blog-main__page-loader').each(function () {
    const loader = new PageLoader($(this), {
      targetPostsSelector: '.blog-main__posts-feed-inner',
      queryParams: { category: $(this).data('category') },
    })
    loader.startObserveLoader()
  })
})
