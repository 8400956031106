import $ from 'jquery'
import { ready } from 'shared/ready'
import matchMediaQueries from '../support/mediaQueries'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

const $window = $(window)

class BlogArticlesHeadersNav {
  constructor($root) {
    this.$root = $root
    this.$articleBody = $('.blog-post__content-main-wrapper')
    this.$headers = this.$articleBody.find('h2')
    this.$articleProgressInner = this.$root.find('.blog-aside-toc__progress-inner')
    this.$tableOfContentItems = this.$root.find('.blog-aside-toc__item')

    const debouncedUpdateState = debounce(this.updateState, 300)
    const throttledUpdateState = throttle(this.updateState, 1000)

    $window.on('scroll', this.checkScrollPosition)
    /* Recalculation of sizes after loading lazy components with throttling */
    $window.on('scroll', throttledUpdateState)
    $window.on('resize', debouncedUpdateState)
    this.$tableOfContentItems.on('click', this.headerHandleClick)
    this.updateState()
  }

  setSizes = () => {
    this.mediaQueries = matchMediaQueries()
    this.viewPortHeight = $window.height()
    this.offsetY = this.viewPortHeight / 2
    this.articlePartsPos = []
    this.articlePos = {
      height: this.$articleBody.height(),
      top: this.$articleBody.offset().top,
    }
    this.articlePos.bottom = this.articlePos.top + this.articlePos.height

    this.$headers.each((i, el) => {
      const nextIndex = i + 1
      const bottomPos =
        nextIndex !== this.$headers.length
          ? this.$headers.eq(nextIndex).offset().top
          : this.articlePos.bottom

      this.articlePartsPos.push({ top: $(el).offset().top, bottom: bottomPos })
    })
  }

  headerHandleClick = e => {
    e.preventDefault()
    const $target = $(e.target.hash)
    if ($target.length) $('html, body').animate({ scrollTop: $target.offset().top - 80 }, 800)
  }

  checkScrollPosition = () => {
    if (this.mediaQueries.isTablet) return

    const scrollTop = $window.scrollTop() + this.offsetY
    const scrollBottom = $window.scrollTop() + this.viewPortHeight

    this.articlePartsPos.forEach((articlePart, i) => {
      if (scrollTop >= articlePart.top && scrollTop < articlePart.bottom) {
        this.$tableOfContentItems.eq(i).addClass('active')
      } else {
        this.$tableOfContentItems.eq(i).removeClass('active')
      }
    })

    if (scrollBottom >= this.articlePos.top && scrollBottom < this.articlePos.bottom) {
      const readingProgress = (scrollBottom - this.articlePos.top) / this.articlePos.height
      this.$articleProgressInner.css({ transform: `scaleX(${readingProgress})` })
    }
  }

  updateState = () => {
    this.setSizes()
    this.checkScrollPosition()
  }
}

ready(function () {
  $('.blog-aside-toc--flat').each((_, el) => new BlogArticlesHeadersNav($(el)))
})

export default BlogArticlesHeadersNav
