import $ from 'jquery'
import { ready } from 'shared/ready'
import { trackMarketingEvent, trackInternalEvent } from '@rio/tracking'
import VisibilityObserver from '../support/VisibilityObserver'

const acFormId = process.env.ACTIVE_CAMPAIGN_NEWSLETTER_FORM_ID || ''

window._load_script = (url, callback) => {
  const head = document.querySelector('head')
  const script = document.createElement('script')

  script.type = 'text/javascript'
  script.src = url
  head.appendChild(script)

  script.onload = () => {
    callback && callback()
    head.removeChild(script)
  }
}

class SubscribeNewsletter {
  constructor($el) {
    if ($el.length === 0) return
    this.classes = {
      success: 'subscribe-newsletter--success',
      insidePost: 'subscribe-newsletter--inside-post',
      insideBlog: 'subscribe-newsletter--inside-blog',
      insideCategory: 'subscribe-newsletter--inside-category',
      insideLandingTop: 'subscribe-newsletter--inside-newsletters_landing_top',
      container: {
        root: 'subscribe-newsletter__container',
        init: 'subscribe-newsletter__container--init',
        success: 'subscribe-newsletter__container--success',
      },
      form: 'subscribe-newsletter__form',
      formMessage: 'field__message',
      fieldError: 'field--error',
      submit: {
        root: 'subscribe-newsletter__form-button',
        showSpinner: 'subscribe-newsletter__form-button--show-spinner',
      },
      recaptcha: {
        root: 'recaptcha',
        wrapper: 'recaptcha-wrapper',
        error: 'recaptcha-wrapper--error',
      },
    }
    this.$el = $el
    this.pageType = this.$el.data('page-type')
    this.$container = this.$el.find(`.${this.classes.container.init}`)
    this.$containerSuccess = this.$el.find(`.${this.classes.container.success}`)
    this.$form = this.$el.find(`.${this.classes.form}`)
    this.$emailField = this.$form.find('[name="email"]')
    this.$message = this.$form.find(`.${this.classes.formMessage}`)
    this.$submit = this.$form.find(`.${this.classes.submit.root}`)
    this.$recaptcha = this.$form.find(`.${this.classes.recaptcha.root}`)
    this.$recaptchaWrapper = this.$form.find(`.${this.classes.recaptcha.wrapper}`)

    this.$form.on('submit', this.handleSubmit)
    this.$emailField.on('focus', () =>
      trackInternalEvent('focus_email_field_of_newsletter_banner', { label: this.pageType }),
    )

    new VisibilityObserver(this.$el, {
      rootMargin: '0%',
      callback: () => trackInternalEvent('see_newsletter_banner', { label: this.pageType }),
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    if (!this.$emailField[0].value) return

    const recaptcha = this.$form.find('.g-recaptcha-response')[0]
    if (recaptcha && !recaptcha.value) {
      this.$recaptchaWrapper.addClass(this.classes.recaptcha.error)
      setTimeout(() => this.$recaptchaWrapper.removeClass(this.classes.recaptcha.error), 600)
      return
    }

    this.$submit.addClass(this.classes.submit.showSpinner)

    // ActiveCampaign API callbacks
    window._show_thank_you = this.handleSuccess
    window._show_error = this.showError

    // See https://resume.activehosted.com/app/forms/1 to get params
    const acFormParams = $.param({
      u: acFormId,
      f: acFormId,
      c: 0,
      m: 0,
      act: 'sub',
      v: 2,
      'g-recaptcha-response': recaptcha ? recaptcha.value : null,
    })
    window._load_script(
      `https://resume.activehosted.com/proc.php?jsonp=true&${this.$form.serialize()}&${acFormParams}`,
      () => this.$submit.removeClass(this.classes.submit.showSpinner),
    )

    this.resetError()
  }

  handleSuccess = () => {
    this.$el.addClass(this.classes.success)

    if (this.$el.hasClass(this.classes.insidePost)) {
      this.showSuccessScreen(200, 50)
      trackMarketingEvent('Blog Post', 'Subscribe to Newsletter')
    } else if (
      this.$el.hasClass(this.classes.insideBlog) ||
      this.$el.hasClass(this.classes.insideCategory)
    ) {
      this.showSuccessScreen(350, 100)
      trackMarketingEvent('Blog Root', 'Subscribe to Newsletter')
    } else if (this.$el.hasClass(this.classes.insideLandingTop)) {
      this.showSuccessLabel(100)
    } else {
      this.showSuccessScreen(350, 100)
    }

    trackInternalEvent('subscribe_newsletter_banner', { label: this.pageType })
  }

  showError = (_, message, html) => {
    // this.$emailField.addClass(this.classes.fieldError)
    // this.$message.text(message)
    this.$recaptchaWrapper.show(0)
    this.$recaptcha.append(html)
  }

  resetError = () => {
    this.$emailField.removeClass(this.classes.fieldError)
    this.$message.text('')
    this.$recaptcha.html('')
    this.$recaptchaWrapper.hide(0)
    this.$recaptchaWrapper.removeClass(this.classes.recaptcha.error)
  }

  showSuccessScreen = (durationEachScreen, delayBetweenScreen) => {
    this.$container.css({ overflow: 'hidden' }).animate(
      { height: this.$containerSuccess.outerHeight(), opacity: 0 },
      {
        duration: durationEachScreen,
        queue: false,
        done: () => {
          this.$container.css({ visibility: 'hidden' })
          setTimeout(() => {
            this.$containerSuccess.css({ visibility: 'visible' })
            this.$containerSuccess.dequeue('success_step')
          }, delayBetweenScreen)
        },
      },
    )
    this.$containerSuccess.animate(
      { opacity: 1 },
      {
        duration: durationEachScreen,
        queue: 'success_step',
        done: () => {
          this.$container.css({ visibility: 'hidden', display: 'none' })
          this.$containerSuccess.css({ position: 'static' })
          this.$containerSuccess.addClass('show-icon')
        },
      },
    )
  }

  showSuccessLabel = delayBetweenScreen => {
    this.$container.addClass('show-success')
    setTimeout(() => {
      this.$containerSuccess.addClass('show-icon')
    }, delayBetweenScreen)
  }
}

ready(function () {
  $('.subscribe-newsletter').each((_, banner) => new SubscribeNewsletter($(banner)))

  // for newsletter landing page
  const $newslettersSubscriberBottom = $('.newsletter-subscriber-bottom')
  $('.newsletters-subscribe__button').on('click', () => {
    $('html, body').animate(
      { scrollTop: $newslettersSubscriberBottom.offset().top - 80 },
      500,
      () => $newslettersSubscriberBottom.find('.field__input').trigger('focus'),
    )
  })
})
