import $ from 'jquery'
import { ready } from 'shared/ready'
import Faq from '../components/faq'
import LanguageSelect from '../components/languageSelect'

class BillingView {
  constructor($el) {
    if ($el.length === 0) return

    // Elements
    this.$el = $el
    new Faq({
      root: $el.find('.billing-faq__questions'),
      classes: {
        question: 'billing-faq__question',
        opened: 'billing-faq__question--opened',
        content: 'billing-faq__question-text',
      },
    })

    new LanguageSelect($el.find('.language-select'))
  }
}

// ---
// Init
// ---
ready(function () {
  return new BillingView($('.billing-faq'))
})
